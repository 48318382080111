import {OverlayModule} from '@angular/cdk/overlay';
import {A11yModule} from '@angular/cdk/a11y';
import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {MatCommonModule} from '@angular/material/core';
import {
  BildTooltipDirective,
  BildTooltipComponent,
  BILD_TOOLTIP_SCROLL_STRATEGY_FACTORY_PROVIDER,
} from './bild-tooltip';
import {MathParagraphComponent} from "../../bild/_shared/math-paragraph/math-paragraph.component";

@NgModule({
    imports: [
        A11yModule,
        CommonModule,
        OverlayModule,
        MatCommonModule,
        MathParagraphComponent,
    ],
  exports: [BildTooltipDirective, BildTooltipComponent, MatCommonModule],
  declarations: [BildTooltipDirective, BildTooltipComponent],
  providers: [BILD_TOOLTIP_SCROLL_STRATEGY_FACTORY_PROVIDER]
})
export class BildTooltipModule {}
