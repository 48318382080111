import {Injectable} from '@angular/core';
import {KatexOptions, renderToString} from 'katex';

@Injectable({
  providedIn: 'root'
})
export class MathLatexService {

  renderToString(equation: any, options?: KatexOptions): string {
    return renderToString(equation, options);
  }
}
