import {Component, ElementRef, Input, Renderer2} from '@angular/core';
import {DomSanitizer, SafeHtml} from '@angular/platform-browser';
import {extractMath} from 'extract-math';
import {MathLatexService} from "@services/math-latex.service";

@Component({
  selector: 'math-paragraph',
  inputs: [],
  standalone: true,
  templateUrl: './math-paragraph.component.html',
  styleUrls: ['./math-paragraph.component.scss'],
  })
export class MathParagraphComponent {
  @Input() inputString: string;

  _html: any = [];

  constructor(private latexService: MathLatexService,
              private renderer: Renderer2,
              private el: ElementRef) {}

  ngOnInit() {}

  ngAfterViewInit() {
    // Break the string into segments ('text', 'inline', and 'display')
    const segments = extractMath(this.inputString, {
      delimiters: {
        inline: ['$', '$'],
        display: ['$$', '$$'],
      },
    });

    // Parse the LaTeX equation to HTML
    for (let i = 0; i < segments.length; i++) {
      if (segments[i]['type'] == 'text') {
        this._html.push(segments[i]['value']);
      } else if (segments[i]['type'] == 'inline') {
        this._html.push(this.latexService.renderToString(segments[i]['value'], {output: 'mathml', throwOnError: false, displayMode: false}));
      } else if (segments[i]['type'] == 'display') {
        this._html.push(this.latexService.renderToString(segments[i]['value'], {output: 'mathml', throwOnError: false, displayMode: true}));
      } else {
        console.warn('An error occurred when parsing the LaTex input. The type of the segment is not recognized.');
      }
    }

    this.renderContent(this._html.join(''));
  }


  private renderContent(htmlContent: string): void {
    const span = this.renderer.createElement('span');
    this.renderer.setProperty(span, 'innerHTML', htmlContent);
    this.renderer.appendChild(this.el.nativeElement, span);
  }
}
