import {
  AfterViewInit, ChangeDetectorRef,
  Component, ElementRef,
  EventEmitter,
  Input, OnDestroy,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';
import {BreakpointObserver, Breakpoints} from '@angular/cdk/layout';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import * as moment from 'moment-timezone';
import * as _ from 'lodash';
import {debounceTime, filter, map, startWith} from 'rxjs/operators';
import {environment} from '../../../../environments/environment';
import {AutocompleteService} from '@services/autocomplete.service';
import {DatePipe} from '@angular/common';
import {ConnectionPositionPair} from '@angular/cdk/overlay';

@UntilDestroy()
@Component({
  selector: 'table-filter',
  templateUrl: './table-filter.component.html',
  styleUrls: ['./table-filter.component.scss'],
  encapsulation: ViewEncapsulation.None,
  })
export class TableFilterComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('cdkFilterContainer') cdkFilterContainer;
  @ViewChild('filterDialogButton') filterDialogButton;
  @ViewChild('includePlaycallInput') includePlaycallInput: ElementRef;
  @ViewChild('excludePlaycallInput') excludePlaycallInput: ElementRef;
  @ViewChild('filterContainer')
  set filterContainer(val) {
    this._filterContainer = val;
    this.calculateContainerHeight();
  }
  get filterContainer(): ElementRef {
    return this._filterContainer;
  }

  _defaultMinValue = 0;
  _defaultMaxValue = 10;
  _visible = false;
  _values;
  _filterContainer: ElementRef;
  _isLocs = false;
  _isTSA = false;
  _toggleLabel = null;

  @Input() set isLocs(val) {
    this._isLocs = val;
    this.rangeSliderOptions['step'] = 1;
    this.cdr.markForCheck();
  };
  get isLocs() {
    return this._isLocs;
  }
  @Input() set isTSA(val) {
    this._isTSA = val;
    this.rangeSliderOptions['step'] = 1;
    this.cdr.markForCheck();
  };
  get isTSA() {
    return this._isTSA;
  }
  @Input() set
  values(vals) {
    const shouldUpdateValues = !this.values || (this.values?.length <= vals?.length);
    this._values = vals;
    this.setupForm(shouldUpdateValues);
  }

  get values() {
    return this._values;
  }
  @Input() activeFilters;
  @Input() checkboxImages;
  @Input() checkboxOptions;
  @Input() displayAsPercent;
  @Input() displayAsTime;
  @Input() filterMethod;
  @Input() isDarkMode: boolean = false;
  @Input() isDollars: boolean = false;
  @Input() maxHeight;
  @Input() percentageDenominator; // Number to base percentage off of (if different than 1)
  @Input() rightOffset = 0;
  @Input() showCheckboxes = false;
  @Input() showDateFilters = false;
  @Input() showPlaycallFilter = false;
  @Input() showSearch = false;
  @Input() showPlayerSearch = false;
  @Input() showCheckboxSearch = false;c
  @Input() showSlider = false;
  @Input() selectedEntities;
  @Input() specialMinFormatting;
  @Input() stepSize: number;
  @Input() tableSelector;
  @Input() useCDKOverlay; // This is needed in certain situations to avoid overflow being hidden
  @Input()
  set defaultMaxValue(val) {
    if (val) {
      this._defaultMaxValue = val;
      this.rangeSliderOptions.ceil = val;
      this.maxSliderValue = val;
    }
  }

  get defaultMaxValue() {
    return this._defaultMaxValue;
  }
  @Input()
  set defaultMinValue(val) {
    if (val) {
      this._defaultMinValue = val;
      this.rangeSliderOptions.floor = val;
      this.minSliderValue = val;
    }
  }

  get defaultMinValue() {
    return this._defaultMinValue;
  }
  @Input()
  set visible(val: boolean) {
    this._visible = val;
    if (val === false) {
      this.showFilter = false;
    }
  }

  get visible() {
    return this._visible;
  }
  @Input()
  set toggleLabel(val: string) {
    this._toggleLabel = val;
  }

  get toggleLabel() {
    return this._toggleLabel;
  }
  @Input()
  set toggleValue(val: boolean) {
    this._toggleValue = val;
  }

  get toggleValue() {
    return this._toggleValue;
  }

  @Input()
  set clearAllFilters(val: boolean) {
    if (val) {
      this.clearFilters();
      this.closeFilter();
    }
  }

  @Output() onFilter: EventEmitter<any> = new EventEmitter();
  @Output() visibilityUpdate: EventEmitter<any> = new EventEmitter();
  @Output() onClose: EventEmitter<any> = new EventEmitter();
  readonly locsMapping = [null, 'ML', 'RO', 'RE', 'KR', 'S', 'TS', 'C', 'F'];

  public positions = [
    new ConnectionPositionPair({
      originX: 'start',
      originY: 'bottom',
    },
    {
      overlayX: 'end',
      overlayY: 'top',
    },
    -296,
    16),
  ];

  playcallFilterControl: FormControl = new FormControl();
  playcallsToInclude: any[] = [];
  playcallsToExclude: any[] = [];
  searchboxFilterControl: FormControl = new FormControl();
  filteredEntities: any[] = [];
  includePlaycallFilterRows: number = 1;
  excludePlaycallFilterRows: number = 1;
  options: any[] = [];
  searchedOptions: any[] = [];
  searchedImages: any[] = [];
  containerHeight: number;
  filterForm: FormGroup;
  isChanged = false;
  isMobile = false;
  selectAllIndeterminate = false;
  showFilter = false;
  xOffset: string;
  yOffset: string;

  maxDate: moment.Moment;
  minDate: moment.Moment;

  tickColor: string = '#ccdcef';
  pointColor: string = '#002d62';
  selectionBarColor: string = '#002a4e';
  minSliderValue: number;
  maxSliderValue: number;
  rangeSliderOptions = {
    getTickColor: (): string => this.tickColor,
    getPointerColor: (): string => this.pointColor,
    getSelectionBarColor: (): string => this.selectionBarColor,
    showTicks: false,
    floor: this.defaultMinValue,
    ceil: this.defaultMaxValue,
    step: 0.1,
    translate: (value: number): string => `${this.formatValue(value)}`,
    animate: false,
    rightToLeft: false,
    noSwitching: true,
  };
  _toggleValue: boolean;

  constructor(protected breakpointObserver: BreakpointObserver,
              protected datePipe: DatePipe,
              protected autocompleteService: AutocompleteService,
              protected cdr: ChangeDetectorRef) { }

  ngOnInit(): void {
    const layoutChanges = this.breakpointObserver.observe([
      Breakpoints.XSmall, Breakpoints.Small,
    ]);

    layoutChanges.pipe(untilDestroyed(this)).subscribe((result) => {
      this.isMobile = result.matches;
    });

    this.initializeForm();
    if (this.isLocs || this.isTSA) {
      this.rangeSliderOptions['step'] = 1;
    } else if (this.stepSize) {
      this.rangeSliderOptions['step'] = this.stepSize;
    } else {
      this.rangeSliderOptions['step'] = 0.1;
    }
    this.playcallFilterControl.valueChanges.pipe(
        debounceTime(environment.typingDebounceTime),
        untilDestroyed(this))
        .subscribe(
            (q) => {
              this.filterEntities(q, 'Playcall');
            },
        );
    window.addEventListener('scroll', this.scroll, true);
  }

  ngAfterViewInit() {
    this.setupForm();
  }

  setupForm(shouldUpdateValues = true) {
    if (this.checkboxOptions) {
      this.options = this.checkboxOptions;
      this.checkboxOptions.forEach((value) => {
        this.filterForm.addControl(value, new FormControl(true));
      });
    } else if (this.values && this.filterForm && shouldUpdateValues) {
      this.options = this.values;
      this.values.forEach((value) => {
        this.filterForm.addControl(value, new FormControl(true));
        if (this.activeFilters && 'startingPoint' in this.activeFilters && !this.activeFilters['startingPoint']?.includes(value)) {
          this.filterForm.get(value).setValue(false);
          this.selectAllIndeterminate = true;
          this.filterForm.get('selectAll').setValue(null);
        }
      });
    } else if (this.activeFilters && this.activeFilters.playCount && this.showSlider) {
      if (this.activeFilters.playCount.minValue && this.minSliderValue === this.defaultMinValue) {
        this.minSliderValue = this.activeFilters.playCount.minValue;
      }
      if (this.activeFilters.playCount.maxValue && this.maxSliderValue === this.defaultMaxValue) {
        this.maxSliderValue = this.activeFilters.playCount.maxValue;
      }
    } else if (!this.isChanged && this.activeFilters && this.activeFilters.playcall && this.showPlaycallFilter) {
      if (this.activeFilters.playcall.included) {
        this.playcallsToInclude = this.activeFilters.playcall.included;
      }
      if (this.activeFilters.playcall.excluded) {
        this.playcallsToExclude = this.activeFilters.playcall.excluded;
      }
    }
  }

  toggleFilterDisplay() {
    if (this.tableSelector) {
      const evalElement = document.querySelector(this.tableSelector);
      if (evalElement) {
        evalElement.scrollIntoView({behavior: 'smooth'});
      }
    }

    this.calculateDisplayPosition(false);
    this.showFilter = !this.showFilter;

    if (this.useCDKOverlay && this.showFilter) {
      setTimeout(() => {
        if (this.filterContainer) {
          this.filterContainer.nativeElement.focus();
        }
      }, 50);
    } else if (this.useCDKOverlay) {
      this.visibilityUpdate.emit(this.showFilter);
    }
  }

  calculateContainerHeight() {
    if (!this.containerHeight) {
      this.containerHeight = this.filterContainer?.nativeElement?.clientHeight;
      this.calculateDisplayPosition(false);
      this.cdr.detectChanges();
    }
  }

  calculateDisplayPosition(scrolling?: boolean) {
    if (this.containerHeight && !this.useCDKOverlay) {
      const boundingClientRect = this.filterDialogButton._elementRef.nativeElement.getBoundingClientRect();
      if (!this.rightOffset && (boundingClientRect.x + 300 > window.innerWidth)) {
        this.rightOffset = 300;
      }
      this.xOffset = boundingClientRect.x - this.rightOffset + 'px';
      // If we're not scrolling, and the overflow container can go off the end of the screen, then display above the button
      if (!scrolling && boundingClientRect.y + this.containerHeight + 28 > window.innerHeight) {
        this.yOffset = (boundingClientRect.y - this.containerHeight) + 'px';
      } else {
        this.yOffset = (boundingClientRect.y + 28) + 'px';
      }
    }
  }

  getRange(start, stop, step = 1) {
    return Array.from({length: (stop - start) / step + 1}, (_, i) => start + (i * step));
  }

  handleIncludedPlaycallAdded(event) {
    this.playcallsToInclude = [...this.playcallsToInclude];
    this.playcallsToInclude.push(event.option.value.name);
    this.includePlaycallInput.nativeElement.value = '';
  }

  addIncludedPlaycallFilterRow() {
    this.includePlaycallFilterRows++;
    this.cdr.markForCheck();
  }

  handleExcludedPlaycallAdded(event) {
    this.playcallsToExclude = [...this.playcallsToExclude];
    this.playcallsToExclude.push(event.option.value.name);
    this.excludePlaycallInput.nativeElement.value = '';
  }

  addExcludedPlaycallFilterRow() {
    this.excludePlaycallFilterRows++;
    this.cdr.markForCheck();
  }

  removeIncludedPlaycall(index) {
    this.playcallsToInclude = [...this.playcallsToInclude];
    this.playcallsToInclude.splice(index, 1);
    this.cdr.markForCheck();
  }

  removeExcludedPlaycall(index) {
    this.playcallsToExclude = [...this.playcallsToExclude];
    this.playcallsToExclude.splice(index, 1);
    this.cdr.markForCheck();
  }

  filterEntities(q: string, entityType: string): void {
    this.filteredEntities = undefined;
    if (q) {
      this.autocompleteService.getElasticSearchResults(q, entityType).pipe(untilDestroyed(this)).subscribe(
          (entities) => {
            this.filteredEntities = _(entities).differenceBy(this.selectedEntities, 'id').value();
            this.cdr.detectChanges();
          },
      );
    }
  }

  filterByValue() {
    this.resetValues();
    const checkValues = [];
    Object.keys(this.filterForm.value).forEach((controlName) => {
      if (this.filterForm.value[controlName] && this.filterForm.get([controlName])) {
        checkValues.push(controlName);
      }
    });
    const emitValues = {
      'filterOptions': null,
      'checkValues': this.selectAllIndeterminate ? checkValues : [], // for all, we send [] so it's quicker and nulls are accounted for
      'maxDate': this.filterForm.get('maxDate').value,
      'minDate': this.filterForm.get('minDate').value,
      'contains': this.filterForm.get('searchInput').value,
      'doesntContain': null,
      'startsWith': null,
      'endsWith': null,
      'equals': null,
      'doesntEqual': null,
      'empty': null,
      'notEmpty': null,
      'null': null,
      'notNull': null,
      'minValue': this.minSliderValue === this.defaultMinValue ? null : this.minSliderValue,
      'maxValue': this.maxSliderValue === this.defaultMaxValue ? null : this.maxSliderValue,
      'toggleValue': this.toggleValue,
      'included': this.playcallsToInclude ? this.playcallsToInclude : null,
      'excluded': this.playcallsToExclude ? this.playcallsToExclude : null,
    };
    let isEmptyValues = true;
    Object.keys(emitValues).forEach((controlName) => {
      const value = emitValues[controlName];
      if (!_.isEmpty(value) || (['string', 'number'].includes(typeof value) && value != null)) {
        isEmptyValues = false;
      }
    });
    // Used to deactive column background color when filter is empty
    emitValues['clearValue'] = isEmptyValues;
    this.onFilter.emit(emitValues);

    this.filterForm.get('filterOptions').setValue(null);
    this.toggleFilterDisplay();
    this.isChanged = true;
  }

  clearFilters() {
    this.resetValues();
    this.toggleFilterDisplay();
    this.selectAllIndeterminate = false;
    this.minSliderValue = this.defaultMinValue;
    this.maxSliderValue = this.defaultMaxValue;
    this.excludePlaycallFilterRows = 1;
    this.playcallsToExclude = [];
    this.includePlaycallFilterRows = 1;
    this.playcallsToInclude = [];
    this.onFilter.emit({});
    this.filterForm.get('filterOptions').setValue(null);
    this.filterForm.get('selectAll').setValue(true);
    this.filterForm.get('minDate').setValue(null);
    this.filterForm.get('maxDate').setValue(null);
    this.filterForm.get('searchInput').setValue(null);
    this.toggleValue = false;
    if (this.values) {
      this.values.forEach((controlName) => {
        if (typeof controlName === 'string' && this.filterForm.get(controlName)) {
          this.filterForm.get(controlName).setValue(true);
          if (this.activeFilters && Object.keys(this.activeFilters).length !== 0 && !this.activeFilters['startingPoint']?.includes(controlName)) {
            this.filterForm.get(controlName).setValue(false);
            this.selectAllIndeterminate = true;
            this.filterForm.get('selectAll').setValue(null);
          }
        }
      });
    }

    this.isChanged = false;
  }

  closeFilter() {
    this.toggleFilterDisplay();
    this.onClose.emit();
  }

  blurFilterContainer($event) {
    if ($event.relatedTarget.closest(".table-filter__overlay-container")) {
      console.log('Prevent Closing Filter Container');
    } else {
      this.closeFilter();
    }
  }

  toggleSelectAll() {
    const selectAllValue = this.filterForm.get('selectAll').value;
    this.selectAllIndeterminate = false;
    this.values.forEach((controlName) => {
      if (this.filterForm.get(controlName)) {
        this.filterForm.get(controlName).setValue(selectAllValue);
      } else if (this.filterForm.controls[controlName]) {
        // Edge case where control name has a '.'
        this.filterForm.controls[controlName].setValue(selectAllValue);
      }
    });
  }

  toggleIndividual(control) {
    // Determine state for selectAll toggle
    const selectAllValue = this.filterForm.get('selectAll').value;
    if (selectAllValue === false) {
      this.selectAllIndeterminate = true;
      this.filterForm.get('selectAll').setValue(null);
    } else if (selectAllValue === true) {
      this.selectAllIndeterminate = true;
      this.filterForm.get('selectAll').setValue(null);
    } else {
      const changedValue = this.filterForm.get(control).value;
      this.selectAllIndeterminate = true;
      const allSame = this.values.every((controlName) => {
        return this.filterForm.get(controlName)?.value === changedValue;
      });

      if (allSame) {
        this.selectAllIndeterminate = false;
        this.filterForm.get('selectAll').setValue(changedValue);
      } else {
        this.filterForm.get('selectAll').setValue(null);
      }
    }
  }

  filterOptions(event: any) {
    const filteredOption = event.target.value;
    const checkValues = [];

    if (filteredOption.length > 0) {
      Object.keys(this.filterForm.value).forEach((controlName) => {
        if (!['filterOptions', 'selectAll', 'minDate', 'maxDate'].includes(controlName) && controlName.toLowerCase().includes(filteredOption.toLowerCase())) {
          checkValues.push(controlName);
        }
      });

      this.values = checkValues;
    } else {
      this.resetValues();
    }
  }

  initializeForm() {
    this.filterForm = new FormGroup({
      filterOptions: new FormControl(),
      selectAll: new FormControl(true),
      maxDate: new FormControl(),
      minDate: new FormControl(),
      searchInput: new FormControl(),
    });
    this.minSliderValue = this.defaultMinValue;
    this.maxSliderValue = this.defaultMaxValue;
    this.rangeSliderOptions.floor = this.defaultMinValue;
    this.rangeSliderOptions.ceil = this.defaultMaxValue;
  }

  resetValues() {
    this.values = this.options;
  }

  scroll = (event) => {
    if (this.visible && !event?.target?.classList?.contains('checkbox-container')) {
      this.calculateDisplayPosition(true);
      this.cdr.markForCheck();
    }
  }

  formatMinMaxValues(value, isPercentile) {
    let formatMin;
    let formatMax;
    if (value !== 1 && value === this.rangeSliderOptions.ceil) {
      formatMax = true;
    } else if (value !== 0 && value === this.rangeSliderOptions.floor) {
      formatMin = true;
    } else if (value == this.rangeSliderOptions.floor && this.specialMinFormatting) {
      formatMin = true;
    }

    if (isPercentile) {
      value = this.percentageDenominator ? (100 * value / this.percentageDenominator).toFixed(1) + '%' : (100 * value).toFixed(1) + '%';
    }

    // Special formatting for dollars
    if (this.isDollars && !isPercentile) {
      value = value >= 0 ? '$' + value.toLocaleString() : '($' + value.toLocaleString().substring(1) + ')';
    }

    if (formatMin) {
      value = this.specialMinFormatting ? this.specialMinFormatting : '<= ' + value;
    } else if (formatMax) {
      value = value + '+';
    }
    return value;
  }

  formatValue(value) {
    if (this.isLocs) {
      return String(this.locsMapping[value]);
    } else if (this.displayAsPercent && [0, 1].includes(value)) {
      return (100 * value) + '%';
    } else if (this.displayAsPercent) {
      return this.formatMinMaxValues(value, true);
    } else if (this.displayAsTime) {
      return this.datePipe.transform(value * 1000, 'mm:ss');
    } else {
      return this.formatMinMaxValues(value, false);
    }
  }

  filterOptionsWithSearchbox() {
    let searchedOptionIndices = this.values.map((option, index) => ({option, index})).filter(({option}) => option.toLowerCase().includes(this.searchboxFilterControl.value.toLowerCase())).map(option => option.index);

    this.searchedOptions = searchedOptionIndices.map(index => this.values[index]);
    this.searchedImages = searchedOptionIndices.map(index => this.checkboxImages[index]);
  }

  ngOnDestroy() {
    window.removeEventListener('scroll', this.scroll, true);
  }
}
