import {ModuleWithProviders} from '@angular/core';
import {ExtraOptions, RouterModule, Routes} from '@angular/router';

const routes: Routes = [
  { path: '', pathMatch: 'full', redirectTo: 'bild', },
  { path: 'bild', loadChildren: () => import('./bild/bild.module').then(m => m.BildModule), data: {preload: true}},
  { path: 'fatigue-cohesion-questionnaire', loadChildren: () => import('./burnout-cohesion-questionnaire/burnout-cohesion-questionnaire.module').then(m => m.BurnoutCohesionQuestionnaireModule) },
  { path: 'sign-in', loadChildren: () => import('./sign-in/sign-in.module').then(m => m.SignInModule) },
  { path: 'wellness-questionnaire', loadChildren: () => import('./wellness-questionnaire/wellness-questionnaire.module').then(m => m.WellnessQuestionnaireModule) },
  { path: 'ion-connect', loadChildren: () => import('./ion-connect/ion-connect.module').then(m => m.IonConnectModule) },
  { path: 'draft', loadChildren: () => import('./draft/draft.module').then(m => m.DraftModule)},
  { path: 'contract', loadChildren: () => import('./contract/contract.module').then(m => m.ContractModule)},
  { path: 'pose', loadChildren: () => import('./pose/pose.module').then(m => m.PoseModule)},
  { path: 'talent-portal', loadChildren: () => import('./talent-portal/talent-portal.module').then(m => m.TalentPortalModule)},
  { path: '**', redirectTo: 'bild'}
];

const routerOptions: ExtraOptions = {
    scrollPositionRestoration: 'enabled',
    anchorScrolling: 'enabled',
    scrollOffset: [0, 64]
};

export const routing: ModuleWithProviders<RouterModule> = RouterModule.forRoot(routes, routerOptions);
