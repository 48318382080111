import {
  VideoSubject,
  videoSubjectEntityToPlayer,
  videoSubjectToFilterName,
  videoSubjectToLabel,
  videoSubjectToPrimaryPlayerLabel,
  videoSubjectToSecondaryPlayerLabel,
} from '@models/constants/video/video-subjects';
import * as _ from 'lodash';

const defaultMaxDistance= 30;
const defaultShotclock = 24;
const defaultGameclock = 720;

const synergyChipDisplay = (isExcluded = false, includedDescriptorFields, excludedDescriptorFields) => {
  const fields = isExcluded ? excludedDescriptorFields : includedDescriptorFields;
  return fields.length > 2 ? fields.length + ' Filters' : fields?.join(', ');
};


const gameClockTranslate = (value: number) => {
  return Math.floor(value/60) + ':' + ((value % 60) < 10 ? ('0' + (value % 60)) : (value % 60));
};

const gameTypeIDLabels = {
  1: 'Preseason',
  7: 'Preseason',
  2: 'Regular Season',
  3: 'All-Star',
  4: 'Postseason',
  5: 'Showcase Tournament',
  6: 'In-Season Tournament Championship',
};

const positionMap = {
  1: 'PG',
  2: 'SG',
  3: 'SF',
  4: 'PF',
  5: 'C'
};

const LOCMap = {
  1: 'RE-',
  2: 'ML',
  3: 'RO',
  4: 'RE',
  5: 'KR',
  6: 'S',
  7: 'TS',
  8: 'C',
  9: 'F'
};

export const getVideoFilterItems = (filtersData, filters, isRecentGeneratedPlaylist, includedDescriptorFields, excludedDescriptorFields) => {
  let firstActionItem;
  if (filters.actions?.length > 0 && (includedDescriptorFields?.length || excludedDescriptorFields?.length)) {
    firstActionItem = videoSubjectToFilterName(filters.actions[0]);
  }
  const isNCAA = filters.leagueIDs?.includes(101);

  const isCrossmatched = _.find(filtersData.filters, ['name', 'nba_player_ids_crossmatched']);
  const isLastDefendingShooter = _.find(filtersData.filters, ['name', 'nba_player_id_last_defending_shooter']);
  const contestedShooter = _.find(filtersData.filters, ['name', 'nba_player_ids_contested_shooter']);
  const isSwitched = _.find(filtersData.filters, ['name', 'nba_player_ids_switched']);
  const isOnCourtOr = _.find(filtersData.filters, filter =>
    filter.name === 'nba_on_court_player_ids' || filter.name === 'synergy_on_court_player_ids'
  )?.or;

  return [
    {
      conditional: filters.actions?.length > 0,
      displayText: filters.actions?.map((act) => videoSubjectToLabel(act)).join(', '),
      filterName: 'actions',
      header: 'Actions',
      ignoreClickEvents: isRecentGeneratedPlaylist,
    },
    {
      conditional: filters.ctgGames?.length > 0,
      displayText: videoSubjectToLabel(filtersData.subject),
      games: filters.ctgGames,
      filterName: 'ctgGames',
      header: 'Games',
      ignoreClickEvents: isRecentGeneratedPlaylist,
    },
    {
      conditional: filters.primaryEntities && filters.primaryEntities.length == null && filtersData.subject != VideoSubject.CHANCES,
      displayText: filters.primaryEntities?.name,
      entity: filters.primaryEntities,
      filterName: videoSubjectEntityToPlayer(filtersData.subject),
      header: videoSubjectToPrimaryPlayerLabel(filtersData.subject),
      ignoreClickEvents: isRecentGeneratedPlaylist,
    },
    {
      conditional: filters.primaryEntities?.length > 0 && filtersData.subject != VideoSubject.CHANCES,
      iterable: filters.primaryEntities,
      filterName: videoSubjectEntityToPlayer(filtersData.subject),
      header: videoSubjectToPrimaryPlayerLabel(filtersData.subject),
      ignoreClickEvents: isRecentGeneratedPlaylist,
    },
    {
      conditional: filters.abstractEntity && filters.abstractEntity.length == null,
      displayText: filters.abstractEntity?.name,
      entity: filters.abstractEntity,
      filterName: 'abstractPlayer',
      header: 'Action Player',
      ignoreClickEvents: isRecentGeneratedPlaylist,
    },
    {
      conditional: filters.abstractEntity?.length > 0,
      iterable: filters.abstractEntity,
      filterName: 'abstractPlayer',
      header: 'Action Player',
      ignoreClickEvents: isRecentGeneratedPlaylist,
    },
    {
      conditional: filters.secondaryEntity?.length > 0,
      iterable: filters.secondaryEntity,
      filterName: filtersData.subject + videoSubjectToSecondaryPlayerLabel(filtersData.subject),
      header: videoSubjectToSecondaryPlayerLabel(filtersData.subject),
      ignoreClickEvents: isRecentGeneratedPlaylist,
    },
    {
      conditional: filters.onCourtEntities?.length > 0,
      iterable: filters.onCourtEntities,
      filterName: 'onCourtEntities',
      header: 'On Court',
      ignoreClickEvents: isRecentGeneratedPlaylist,
    },
    {
      conditional: isOnCourtOr,
      displayText: 'True',
      header: 'OR',
      filterName: 'onCourtEntities',
      ignoreClickEvents: isRecentGeneratedPlaylist,
    },
    {
      conditional: filters.offCourtEntities?.length > 0,
      iterable: filters.offCourtEntities,
      filterName: 'offCourtEntities',
      header: 'Off Court',
      ignoreClickEvents: isRecentGeneratedPlaylist,
    },
    {
      conditional: filters.offensiveLineupEntities?.length > 0,
      iterable: filters.offensiveLineupEntities,
      filterName: 'offensiveLineupEntities',
      header: 'Offensive Player',
      ignoreClickEvents: isRecentGeneratedPlaylist,
    },
    {
      conditional: filters.defensiveLineupEntities?.length > 0,
      iterable: filters.defensiveLineupEntities,
      filterName: 'defensiveLineupEntities',
      header: 'Defensive Player',
      ignoreClickEvents: isRecentGeneratedPlaylist,
    },
    {
      conditional: filters.primaryDefensivePlayer && filters.primaryDefensivePlayer.length == null,
      displayText: filters.primaryDefensivePlayer?.name,
      entity: filters.primaryDefensivePlayer,
      header: 'Primary Defender',
      ignoreClickEvents: isRecentGeneratedPlaylist,
    },
    {
      conditional: filters.primaryDefensivePlayer?.length > 0,
      iterable: filters.primaryDefensivePlayer,
      filterName: 'primaryDefensivePlayer',
      header: 'Primary Defender',
      ignoreClickEvents: isRecentGeneratedPlaylist,
    },
    {
      conditional: filters.teamEntities?.length > 0,
      iterable: filters.teamEntities,
      filterName: 'teamEntities',
      header: 'Teams',
      ignoreClickEvents: isRecentGeneratedPlaylist,
    },
    {
      conditional: filters.offensiveTeamEntities?.length > 0,
      iterable: filters.offensiveTeamEntities,
      filterName: 'offensiveTeamEntities',
      header: 'Offensive Teams',
      ignoreClickEvents: isRecentGeneratedPlaylist,
    },
    {
      conditional: filters.defensiveTeamEntities?.length > 0,
      iterable: filters.defensiveTeamEntities,
      filterName: 'defensiveTeamEntities',
      header: 'Defensive Teams',
      ignoreClickEvents: isRecentGeneratedPlaylist,
    },
    {
      conditional: filters.secondaryEntity && filters.secondaryEntity.length == null,
      displayText: filters.secondaryEntity?.name,
      entity: filters.secondaryEntity,
      filterName: filtersData.subject + videoSubjectToSecondaryPlayerLabel(filtersData.subject),
      header: videoSubjectToSecondaryPlayerLabel(filtersData.subject),
      ignoreClickEvents: isRecentGeneratedPlaylist,
    },
    {
      conditional: filters.periods?.length > 0,
      displayText: filters.periods?.join(', '),
      filterName: 'periods',
      header: 'Period' + (filters.periods?.length > 1 ? 's' : ''),
      ignoreClickEvents: isRecentGeneratedPlaylist,
    },
    {
      conditional: filters.minGameclock || filters.maxGameclock != defaultGameclock,
      displayText: gameClockTranslate(filters.minGameclock) + ' - ' + gameClockTranslate(filters.maxGameclock),
      filterName: 'minGameclock',
      header: 'Game Clock',
      ignoreClickEvents: isRecentGeneratedPlaylist,
    },
    {
      conditional: filters.minShotclock || filters.maxShotclock != defaultShotclock,
      displayText: filters.minShotclock + ' - ' + filters.maxShotclock,
      filterName: 'minShotclock',
      header: 'Shot Clock',
      ignoreClickEvents: isRecentGeneratedPlaylist,
    },
    {
      conditional: !filters.ctgGames?.length && (filters.minDate || filters.maxDate),
      maxDate: filters.maxDate,
      minDate: filters.minDate,
      filterName: 'minDate',
      header: 'Date',
      ignoreClickEvents: isRecentGeneratedPlaylist,
    },
    {
      conditional: !filters.ctgGames?.length && filters.game_type?.length > 0,
      displayText: filters.game_type?.map((gameType) => gameTypeIDLabels[gameType]).join(', '),
      filterName: 'game_type',
      header: 'Game Type',
      ignoreClickEvents: isRecentGeneratedPlaylist,
    },
    {
      conditional: filters.charting_category_name?.length > 0,
      displayText: filters.charting_category_name?.join(', '),
      filterName: 'charting_category_name',
      header: 'Charting Categor' + (filters.charting_category_name?.length > 1 ? 'ies' : 'y'),
      ignoreClickEvents: isRecentGeneratedPlaylist,
    },
    {
      conditional: filters.charting_result?.length > 0,
      displayText: filters.charting_result?.join(', '),
      filterName: 'charting_result',
      header: 'Charting Result',
      ignoreClickEvents: isRecentGeneratedPlaylist,
    },
    {
      conditional: !filters.ctgGames?.length && !(filters.minDate || filters.maxDate) && filters.seasons?.length,
      displayText: filters.seasons?.join(', '),
      header: 'Season' + (filters.seasons?.length > 1 ? 's' : ''),
      filterName: 'season',
      ignoreClickEvents: isRecentGeneratedPlaylist,
    },
    {
      conditional: filters.starttype?.length > 0,
      displayText: filters.starttype?.join(', '),
      filterName: 'starttype',
      header: 'Start Type' + (filters.starttype?.length > 1 ? 's' : ''),
      ignoreClickEvents: isRecentGeneratedPlaylist,
    },
    {
      conditional: filters.outcomes?.length > 0,
      displayText: filters.outcomes?.join(', '),
      filterName: 'outcomes',
      header: 'Outcome' + (filters.starttype?.length > 1 ? 's' : ''),
      ignoreClickEvents: isRecentGeneratedPlaylist,
    },
    {
      conditional: filters.regionsin?.length > 0,
      displayText: filters.regionsin?.length > 3 ? filters.regionsin?.length + ' Regions' : filters.regionsin?.join(', '),
      filterName: 'regionsin',
      header: 'Region' + (filters.regionsin?.length > 1 ? 's' : ''),
      ignoreClickEvents: isRecentGeneratedPlaylist,
    },
    {
      conditional: filters.potentialcatchandshoot?.length > 0,
      displayText: filters.potentialcatchandshoot?.join(', '),
      filterName: 'potentialcatchandshoot',
      header: 'Catch & Shoot',
      ignoreClickEvents: isRecentGeneratedPlaylist,
    },
    {
      conditional: excludedDescriptorFields?.length > 0,
      displayText: synergyChipDisplay(true, includedDescriptorFields, excludedDescriptorFields),
      filterName: firstActionItem,
      header: 'Excluded Attributes',
      ignoreClickEvents: isRecentGeneratedPlaylist,
    },
    {
      conditional: includedDescriptorFields?.length > 0,
      displayText: synergyChipDisplay(false, includedDescriptorFields, excludedDescriptorFields),
      filterName: firstActionItem,
      header: 'Included Attributes',
      ignoreClickEvents: isRecentGeneratedPlaylist,
    },
    {
      conditional: filters.synergy_shot_type?.length > 0,
      displayText: filters.synergy_shot_type?.length < 4 ? filters.synergy_shot_type?.join(', ') : `${filters.synergy_shot_type?.length} Shot Types`,
      filterName: 'synergy_shot_type',
      header: 'Shot Types',
      ignoreClickEvents: isRecentGeneratedPlaylist,
    },
    {
      conditional: filters.assist != null,
      displayText: (filters.assist == 0 || filters.assist?.length && filters.assist[0] == false) ? 'False' : 'True',
      filterName: 'shotAttributes',
      header: 'Assisted',
      ignoreClickEvents: isRecentGeneratedPlaylist,
    },
    {
      conditional: filters.block != null,
      displayText: (filters.block == 0 || filters.block?.length && filters.block[0] == false) ? 'False' : 'True',
      filterName: 'shotAttributes',
      header: 'Blocked',
      ignoreClickEvents: isRecentGeneratedPlaylist,
    },
    {
      conditional: filters.catch_and_shoot != null,
      displayText: (filters.catch_and_shoot == 0 || filters.catch_and_shoot?.length && filters.catch_and_shoot[0] == false) ? 'False' : 'True',
      filterName: 'shotAttributes',
      header: 'Catch and Shoot',
      ignoreClickEvents: isRecentGeneratedPlaylist,
    },
    {
      conditional: filters.shooting_foul != null,
      displayText: (filters.shooting_foul == 0 || filters.shooting_foul?.length && filters.shooting_foul[0] == false) ? 'False' : 'True',
      filterName: 'shotAttributes',
      header: 'Fouled',
      ignoreClickEvents: isRecentGeneratedPlaylist,
    },
    {
      conditional: filters.fg_made != null,
      displayText: (filters.fg_made == 0 || filters.fg_made?.length && filters.fg_made[0] == false) ? 'False' : 'True',
      filterName: 'shotAttributes',
      header: 'Made',
      ignoreClickEvents: isRecentGeneratedPlaylist,
    },
    {
      conditional: filters.fg_3_attempted != null,
      displayText: (filters.fg_3_attempted == 0 || filters.fg_3_attempted?.length && filters.fg_3_attempted[0] == false) ? 'False' : 'True',
      filterName: 'shotAttributes',
      header: 'Three Pointer',
      ignoreClickEvents: isRecentGeneratedPlaylist,
    },
    {
      conditional: filters.nba_player_ids_defending_1 != null ||
                   filters.nba_player_ids_defending_2 != null ||
                   filters.nba_player_ids_defending_3 != null ||
                   filters.nba_player_ids_defending_4 != null ||
                   filters.nba_player_ids_defending_5 != null,
      displayText: [
        filters.nba_player_ids_defending_1 != null ? `${positionMap[1]}` : '',
        filters.nba_player_ids_defending_2 != null ? `${positionMap[2]}` : '',
        filters.nba_player_ids_defending_3 != null ? `${positionMap[3]}` : '',
        filters.nba_player_ids_defending_4 != null ? `${positionMap[4]}` : '',
        filters.nba_player_ids_defending_5 != null ? `${positionMap[5]}` : ''
      ].filter(text => text !== '').join(', '),
      filterName: 'defenseAttributes',
      header: 'Defending',
      ignoreClickEvents: isRecentGeneratedPlaylist,
    },
    {
      conditional: filters.nba_player_ids_defended_by_1 != null ||
                   filters.nba_player_ids_defended_by_2 != null ||
                   filters.nba_player_ids_defended_by_3 != null ||
                   filters.nba_player_ids_defended_by_4 != null ||
                   filters.nba_player_ids_defended_by_5 != null,
      displayText: [
        filters.nba_player_ids_defended_by_1 != null ? `${positionMap[1]}` : '',
        filters.nba_player_ids_defended_by_2 != null ? `${positionMap[2]}` : '',
        filters.nba_player_ids_defended_by_3 != null ? `${positionMap[3]}` : '',
        filters.nba_player_ids_defended_by_4 != null ? `${positionMap[4]}` : '',
        filters.nba_player_ids_defended_by_5 != null ? `${positionMap[5]}` : ''
      ].filter(text => text !== '').join(', '),
      filterName: 'defenseAttributes',
      header: 'Defended By',
      ignoreClickEvents: isRecentGeneratedPlaylist,
    },
    {
      conditional: filters.nba_player_ids_expected_defending_1 != null ||
                   filters.nba_player_ids_expected_defending_2 != null ||
                   filters.nba_player_ids_expected_defending_3 != null ||
                   filters.nba_player_ids_expected_defending_4 != null ||
                   filters.nba_player_ids_expected_defending_5 != null,
      displayText: [
        filters.nba_player_ids_expected_defending_1 != null ? `${positionMap[1]}` : '',
        filters.nba_player_ids_expected_defending_2 != null ? `${positionMap[2]}` : '',
        filters.nba_player_ids_expected_defending_3 != null ? `${positionMap[3]}` : '',
        filters.nba_player_ids_expected_defending_4 != null ? `${positionMap[4]}` : '',
        filters.nba_player_ids_expected_defending_5 != null ? `${positionMap[5]}` : ''
      ].filter(text => text !== '').join(', '),
      filterName: 'defenseAttributes',
      header: 'Expected Defending',
      ignoreClickEvents: isRecentGeneratedPlaylist,
    },
    {
      conditional: filters.nba_player_ids_defending_ml != null ||
                   filters.nba_player_ids_defending_ro != null ||
                   filters.nba_player_ids_defending_re != null ||
                   filters.nba_player_ids_defending_kr != null ||
                   filters.nba_player_ids_defending_s != null ||
                   filters.nba_player_ids_defending_ts != null ||
                   filters.nba_player_ids_defending_c != null ||
                   filters.nba_player_ids_defending_f != null ||
                   filters.nba_player_ids_defending_re_aggregate != null ||
                   filters.nba_player_ids_crossmatched_defending_ml != null ||
                   filters.nba_player_ids_crossmatched_defending_ro != null ||
                   filters.nba_player_ids_crossmatched_defending_re != null ||
                   filters.nba_player_ids_crossmatched_defending_kr != null ||
                   filters.nba_player_ids_crossmatched_defending_s != null ||
                   filters.nba_player_ids_crossmatched_defending_ts != null ||
                   filters.nba_player_ids_crossmatched_defending_c != null ||
                   filters.nba_player_ids_crossmatched_defending_f != null,
      displayText: [
        filters.nba_player_ids_defending_f != null ? `${LOCMap[9]}` : '',
        filters.nba_player_ids_defending_c != null ? `${LOCMap[8]}` : '',
        filters.nba_player_ids_defending_ts != null ? `${LOCMap[7]}` : '',
        filters.nba_player_ids_defending_s != null ? `${LOCMap[6]}` : '',
        filters.nba_player_ids_defending_kr != null ? `${LOCMap[5]}` : '',
        filters.nba_player_ids_defending_re != null ? `${LOCMap[4]}` : '',
        filters.nba_player_ids_defending_ro != null ? `${LOCMap[3]}` : '',
        filters.nba_player_ids_defending_ml != null ? `${LOCMap[2]}` : '',
        filters.nba_player_ids_defending_re_aggregate != null ? `${LOCMap[1]}` : '',
        filters.nba_player_ids_crossmatched_defending_f != null ? `${LOCMap[9]}` : '',
        filters.nba_player_ids_crossmatched_defending_c != null ? `${LOCMap[8]}` : '',
        filters.nba_player_ids_crossmatched_defending_ts != null ? `${LOCMap[7]}` : '',
        filters.nba_player_ids_crossmatched_defending_s != null ? `${LOCMap[6]}` : '',
        filters.nba_player_ids_crossmatched_defending_kr != null ? `${LOCMap[5]}` : '',
        filters.nba_player_ids_crossmatched_defending_re != null ? `${LOCMap[4]}` : '',
        filters.nba_player_ids_crossmatched_defending_ro != null ? `${LOCMap[3]}` : '',
        filters.nba_player_ids_crossmatched_defending_ml != null ? `${LOCMap[2]}` : ''
      ].filter(text => text !== '').join(', '),
      filterName: 'defenseMatchupLOCs',
      header: 'Defending LOC',
      ignoreClickEvents: isRecentGeneratedPlaylist,
    },
    {
      conditional: filters.nba_player_ids_expected_defending_ml != null ||
                   filters.nba_player_ids_expected_defending_ro != null ||
                   filters.nba_player_ids_expected_defending_re != null ||
                   filters.nba_player_ids_expected_defending_kr != null ||
                   filters.nba_player_ids_expected_defending_s != null ||
                   filters.nba_player_ids_expected_defending_ts != null ||
                   filters.nba_player_ids_expected_defending_c != null ||
                   filters.nba_player_ids_expected_defending_f != null ||
                   filters.nba_player_ids_expected_defending_re_aggregate != null,
      displayText: [
        filters.nba_player_ids_expected_defending_f != null ? `${LOCMap[9]}` : '',
        filters.nba_player_ids_expected_defending_c != null ? `${LOCMap[8]}` : '',
        filters.nba_player_ids_expected_defending_ts != null ? `${LOCMap[7]}` : '',
        filters.nba_player_ids_expected_defending_s != null ? `${LOCMap[6]}` : '',
        filters.nba_player_ids_expected_defending_kr != null ? `${LOCMap[5]}` : '',
        filters.nba_player_ids_expected_defending_re != null ? `${LOCMap[4]}` : '',
        filters.nba_player_ids_expected_defending_ro != null ? `${LOCMap[3]}` : '',
        filters.nba_player_ids_expected_defending_ml != null ? `${LOCMap[2]}` : '',
        filters.nba_player_ids_expected_defending_re_aggregate != null ? `${LOCMap[1]}` : '',
      ].filter(text => text !== '').join(', '),
      filterName: 'defenseMatchupLOCsExpected',
      header: 'Defending LOC (Exp.)',
      ignoreClickEvents: isRecentGeneratedPlaylist,
    },
    {
      conditional: filters.nba_player_id_last_defending_shooter != null,
      displayText: (filters.nba_player_id_last_defending_shooter != 0 && !isLastDefendingShooter?.not) ? 'True' : 'False',
      header: 'Last Defending Shooter',
      filterName: 'nbaPlayerIDLastDefendingShooter',
      ignoreClickEvents: isRecentGeneratedPlaylist,
    },
    {
      conditional: filters.nba_player_ids_contested_shooter != null,
      displayText: (filters.nba_player_ids_contested_shooter != 0  && !contestedShooter?.not)? 'True' : 'False',
      header: 'Contested Shooter',
      filterName: 'nbaPlayerIDsContestedShooter',
      ignoreClickEvents: isRecentGeneratedPlaylist,
    },
    {
      conditional: filters.nba_player_ids_crossmatched != null,
      displayText: (filters.nba_player_ids_crossmatched != 0  && !isCrossmatched?.not) ? 'True' : 'False',
      header: 'Crossmatched',
      filterName: 'nbaPlayerIDsCrossmatched',
      ignoreClickEvents: isRecentGeneratedPlaylist,
    },
    {
      conditional: filters.nba_player_ids_switched != null,
      displayText: (filters.nba_player_ids_switched != 0 && !isSwitched?.not) ? 'True' : 'False',
      header: 'Switched',
      filterName: 'nbaPlayerIDsSwitched',
      ignoreClickEvents: isRecentGeneratedPlaylist,
    },
    {
      conditional: filters.action_ball_screen_bhdefender_nbapersonids != null,
      displayText: 'Ballhandler Defender',
      header: 'Ball Screens',
      filterName: 'nbaPlayerIDsActionBallScreenBHDefender',
      ignoreClickEvents: isRecentGeneratedPlaylist,
    },
    {
      conditional: filters.action_ball_screen_scdefender_nbapersonids != null,
      displayText: 'Screener Defender',
      header: 'Ball Screens',
      filterName: 'nbaPlayerIDsActionBallScreenSCDefender',
      ignoreClickEvents: isRecentGeneratedPlaylist,
    },
    {
      conditional: filters.action_off_ball_screen_ctdefender_nbapersonids != null,
      displayText: 'Cutter Defender',
      header: 'Off-Ball Screen',
      filterName: 'nbaPlayerIDsActionOffBallScreenCTDefender',
      ignoreClickEvents: isRecentGeneratedPlaylist,
    },
    {
      conditional: filters.action_off_ball_screen_scdefender_nbapersonids != null,
      displayText: 'Screener Defender',
      header: 'Off Ball Screen',
      filterName: 'nbaPlayerIDsActionOffBallScreenSCDefender',
      ignoreClickEvents: isRecentGeneratedPlaylist,
    },
    {
      conditional: filters.action_isolation_bhdefender_nbapersonids != null,
      displayText: 'Ballhandler Defender',
      header: 'Isolation',
      filterName: 'nbaPlayerIDsActionIsolationBHDefender',
      ignoreClickEvents: isRecentGeneratedPlaylist,
    },
    {
      conditional: filters.action_drive_bhdefender_nbapersonids != null,
      displayText: 'Ballhandler Defender',
      header: 'Drive',
      filterName: 'nbaPlayerIDsActionDriveBHDefender',
      ignoreClickEvents: isRecentGeneratedPlaylist,
    },
    {
      conditional: filters.action_post_bhdefender_nbapersonids != null,
      displayText: 'Ballhandler Defender',
      header: 'Post Up',
      filterName: 'nbaPlayerIDsActionPostBHDefender',
      ignoreClickEvents: isRecentGeneratedPlaylist,
    },
    {
      conditional: filters.action_post_doubler_nbapersonids != null,
      displayText: 'True',
      header: 'Doubler',
      filterName: 'nbaPlayerIDsActionPostDoubler',
      ignoreClickEvents: isRecentGeneratedPlaylist,
    },
    {
      conditional: filters.action_ball_screen_ballhandler_nbapersonids != null,
      displayText: 'Ballhandler',
      header: 'Ball Screens',
      filterName: 'nbaPlayerIDsActionBallScreenBH',
      ignoreClickEvents: isRecentGeneratedPlaylist,
    },
    {
      conditional: filters.action_isolation_ballhandler_nbapersonids != null,
      displayText: 'Ballhandler',
      header: 'Isolation',
      filterName: 'nbaPlayerIDsActionIsolationBH',
      ignoreClickEvents: isRecentGeneratedPlaylist,
    },
    {
      conditional: filters.action_drive_ballhandler_nbapersonids != null,
      displayText: 'Ballhandler',
      header: 'Drive',
      filterName: 'nbaPlayerIDsActionDriveBH',
      ignoreClickEvents: isRecentGeneratedPlaylist,
    },
    {
      conditional: filters.action_post_ballhandler_nbapersonids != null,
      displayText: 'Ballhandler',
      header: 'Post Up',
      filterName: 'nbaPlayerIDsActionPostBH',
      ignoreClickEvents: isRecentGeneratedPlaylist,
    },
    {
      conditional: filters.action_off_ball_screen_screener_nbapersonids != null,
      displayText: 'Screener',
      header: 'Off Ball Screens',
      filterName: 'nbaPlayerIDsActionOffBallScreenScreener',
      ignoreClickEvents: isRecentGeneratedPlaylist,
    },
    {
      conditional: filters.action_off_ball_screen_cutter_nbapersonids != null,
      displayText: 'Cutter',
      header: 'Off Ball Screens',
      filterName: 'nbaPlayerIDsActionOffBallScreenCutter',
      ignoreClickEvents: isRecentGeneratedPlaylist,
    },
    {
      conditional: filters.action_off_ball_screen_toucher_nbapersonids != null,
      displayText: 'Toucher',
      header: 'Off Ball Screens',
      filterName: 'nbaPlayerIDsActionOffBallScreenToucher',
      ignoreClickEvents: isRecentGeneratedPlaylist,
    },
    {
      conditional: filters.shot_contestedness?.length > 0,
      displayText: filters.shot_contestedness?.join(', '),
      filterName: 'shot_contestedness',
      header: 'Contestedness',
      ignoreClickEvents: isRecentGeneratedPlaylist,
    },
    {
      conditional: filters.shot_setup_advanced?.length > 0,
      displayText: filters.shot_setup_advanced?.join(', '),
      filterName: 'shot_setup_advanced',
      header: 'Setup/Contestedness',
      ignoreClickEvents: isRecentGeneratedPlaylist,
    },
    {
      conditional: filters.minDistance || filters.maxDistance != defaultMaxDistance,
      displayText: filters.minDistance + ' - ' + filters.maxDistance,
      filterName: 'eagle_shot_distance',
      header: 'Distance',
      ignoreClickEvents: isRecentGeneratedPlaylist,
    },
    {
      conditional: filters.shot_zone_basic?.length > 0,
      displayText: filters.shot_zone_basic?.join(', '),
      filterName: 'shot_zone_basic',
      header: 'Shot Zone' + (filters.shot_zone_basic?.length > 1 ? 's' : ''),
      ignoreClickEvents: isRecentGeneratedPlaylist,
    },
    {
      conditional: filters.shot_distribution?.length > 0,
      displayText: filters.shot_distribution?.map((dist) => dist.charAt(0).toUpperCase() + dist.slice(1).toLowerCase())?.join(', '),
      filterName: 'shot_distribution',
      header: 'Shot Distribution' + (filters.shot_distribution?.length > 1 ? 's' : ''),
      ignoreClickEvents: isRecentGeneratedPlaylist,
    },
    {
      conditional: filters.minQEFG || filters.maxQEFG != 1,
      displayText: filters.minQEFG + ' - ' + filters.maxQEFG,
      filterName: 'shot_qefg',
      header: 'qeFG',
      ignoreClickEvents: isRecentGeneratedPlaylist,
    },
    {
      conditional: filters.minXEFG || filters.maxXEFG != 1,
      displayText: filters.minXEFG + ' - ' + filters.maxXEFG,
      filterName: 'shot_xefg',
      header: 'xeFG',
      ignoreClickEvents: isRecentGeneratedPlaylist,
    },
    {
      conditional: filters.nba_chance_id?.length > 0,
      displayText: `${filters.nba_chance_id?.length} Selected`,
      filterName: 'nbaChances',
      header: 'NBA Chance IDs',
      ignoreClickEvents: isRecentGeneratedPlaylist,
    },
    {
      conditional: filters.eagle_chance_id?.length > 0,
      displayText: `${filters.eagle_chance_id?.length} Selected`,
      filterName: 'eagleChances',
      header: 'Eagle Chance IDs',
      ignoreClickEvents: isRecentGeneratedPlaylist,
    },
    {
      conditional: filters.synergy_event_id?.length > 0,
      displayText: `${filters.synergy_event_id?.length} Selected`,
      filterName: 'events',
      header: 'Synergy Event IDs',
      ignoreClickEvents: isRecentGeneratedPlaylist,
    },
    {
      conditional: filters.nba_possession_id?.length > 0,
      displayText: filters.nba_possession_id?.join(', '),
      filterName: 'possessions',
      header: 'Possession ID' + (filters.nba_possession_id?.length > 1 ? 's' : ''),
      ignoreClickEvents: isRecentGeneratedPlaylist,
    },
    {
      conditional: filters.eagle_transition_flag != null,
      displayText: (filters.eagle_transition_flag == 0 || filters.eagle_transition_flag?.length && filters.eagle_transition_flag == false) ? 'False' : 'True',
      filterName: 'eagle_transition_flag',
      header: 'Transition',
      ignoreClickEvents: isRecentGeneratedPlaylist,
    },
  ];
};
