import {
  touchOutcomeOptions,
  touchPotentialCatchAndShootOptions,
  touchRegionOptions,
  touchStartTypeOptions,
} from '@models/constants/touch-options';
import {chartingCategoryOptions, chartingResultOptions} from '@models/constants/charting-options';
import {
  VideoSubject,
  videoSubjectToPrimaryPlayerLabel,
  videoSubjectToSecondaryPlayerLabel,
} from '@models/constants/video/video-subjects';

export enum VideoTables {
  ABSTRACT = 'abstract',
  ACTION = 'action',
  CHANCE_DETAILS = 'chancedetails',
  CHARTING = 'chartingdetails',
  FREE_THROWS = 'freethrows',
  TOUCHER = 'toucher',
  TOUCHES = 'touches',
}

export enum VideoPlayerFields {
  ON_COURT = 'on_court_player_ids',
  SHOOTER = 'shooter_id',
  ASSISTER = 'assister_id',
  REBOUNDER = 'rebounder_id',
  STEALER = 'stealer_id',
  BLOCKER = 'blocker_id',
  TURNOVERER = 'turnoverer_id',
  FOULER = 'fouler_id',
  FOULEE = 'fouled_id',
  TOUCHER = 'nbaid',
  CHARTING_PLAYER = 'nba_person_id',
}

export const VideoDefenseTags = [
  'nba_player_ids_defending_1',
  'nba_player_ids_defending_2',
  'nba_player_ids_defending_3',
  'nba_player_ids_defending_4',
  'nba_player_ids_defending_5',
  'nba_player_ids_defending_ml',
  'nba_player_ids_defending_ro',
  'nba_player_ids_defending_re',
  'nba_player_ids_defending_kr',
  'nba_player_ids_defending_s',
  'nba_player_ids_defending_ts',
  'nba_player_ids_defending_c',
  'nba_player_ids_defending_f',
  'nba_player_ids_expected_defending_ml',
  'nba_player_ids_expected_defending_ro',
  'nba_player_ids_expected_defending_re',
  'nba_player_ids_expected_defending_kr',
  'nba_player_ids_expected_defending_s',
  'nba_player_ids_expected_defending_ts',
  'nba_player_ids_expected_defending_c',
  'nba_player_ids_expected_defending_f',
  'nba_player_ids_expected_defending_1',
  'nba_player_ids_expected_defending_2',
  'nba_player_ids_expected_defending_3',
  'nba_player_ids_expected_defending_4',
  'nba_player_ids_expected_defending_5',
  'nba_player_ids_crossmatched_defending_ml',
  'nba_player_ids_crossmatched_defending_ro',
  'nba_player_ids_crossmatched_defending_re',
  'nba_player_ids_crossmatched_defending_kr',
  'nba_player_ids_crossmatched_defending_s',
  'nba_player_ids_crossmatched_defending_ts',
  'nba_player_ids_crossmatched_defending_c',
  'nba_player_ids_crossmatched_defending_f',
  'nba_player_ids_crossmatched',
  'nba_player_ids_switched',
  'nba_player_ids_contested_shooter',
  'nba_player_id_last_defending_shooter',
  'action_ball_screen_bhdefender_nbapersonids',
  'action_ball_screen_scdefender_nbapersonids',
  'action_isolation_bhdefender_nbapersonids',
  'action_drive_bhdefender_nbapersonids',
  'action_post_bhdefender_nbapersonids',
  'action_post_doubler_nbapersonids',
  'action_off_ball_screen_ctdefender_nbapersonids',
  'action_off_ball_screen_scdefender_nbapersonids',
]

export const VideoOffenseTags = [
  'action_ball_screen_ballhandler_nbapersonids',
  'action_isolation_ballhandler_nbapersonids',
  'action_drive_ballhandler_nbapersonids',
  'action_post_ballhandler_nbapersonids',
  'action_off_ball_screen_screener_nbapersonids',
  'action_off_ball_screen_cutter_nbapersonids',
  'action_off_ball_screen_toucher_nbapersonids',
]

export const SpecialDefenseTags = [
  'nba_player_ids_expected_defending_1',
  'nba_player_ids_expected_defending_2',
  'nba_player_ids_expected_defending_3',
  'nba_player_ids_expected_defending_4',
  'nba_player_ids_expected_defending_5'
]

export const BooleanDefenseTags = [
  'nba_player_ids_crossmatched',
  'nba_player_ids_switched',
  'nba_player_ids_contested_shooter',
  'nba_player_id_last_defending_shooter',
]

export const shotActionItems = [
  {
    name: 'Shots',
    actionItems: [
      {
        header: 'Top',
        options: [
          {
            name: 'Shots Attributes',
            filterName: 'shotAttributes',
            subOptions: [
              {name: 'Assisted', columnName: 'assist', isAdvancedCheckbox: true},
              {name: 'Blocked', columnName: 'block', isAdvancedCheckbox: true},
              {name: 'Catch and Shoot', columnName: 'catch_and_shoot', isNCAAExcluded: true, isAdvancedCheckbox: true, isBoolean: true},
              {name: 'Fouled', columnName: 'shooting_foul', isAdvancedCheckbox: true},
              {name: 'Made', columnName: 'fg_made', isAdvancedCheckbox: true},
              {name: 'Three Pointer', columnName: 'fg_3_attempted', isAdvancedCheckbox: true},
            ],
          },
        ],
      },
      {
        header: 'Players', options: [
          {name: 'Assister', isAutocomplete: true, isSecondaryEntity: true, filterName: VideoSubject.SHOTS + videoSubjectToSecondaryPlayerLabel(VideoSubject.SHOTS), columnName: VideoPlayerFields.ASSISTER},
          {name: 'Shooter', isAutocomplete: true, isPrimaryEntity: true, filterName: VideoSubject.SHOTS + videoSubjectToPrimaryPlayerLabel(VideoSubject.SHOTS), columnName: VideoPlayerFields.SHOOTER},
        ],
      },
      {header: 'Shots Context', options: [
        {
          name: 'Contest Level',
          fieldName: 'shot_contestedness',
          filterName: 'shot_contestedness',
          isNCAAExcluded: true,
          subOptions: [
            {name: 'Low', isSimpleCheckbox: true, value: 'Low'},
            {name: 'Moderate', isSimpleCheckbox: true, value: 'Moderate'},
            {name: 'High', isSimpleCheckbox: true, value: 'High'},
          ],
        },
        {
          name: 'Setup/Contest',
          fieldName: 'shot_setup_advanced',
          filterName: 'shot_setup_advanced',
          isNBAExcluded: true,
          subOptions: [
            {name: 'Catch and Shoot', isSimpleCheckbox: true, hasChildrenOptions: true, value: ['C&S Guarded', 'C&S Open', 'C&S Other']},
            {name: 'Guarded', isSimpleCheckbox: true, isSubOption: true, value: 'C&S Guarded'},
            {name: 'Open', isSimpleCheckbox: true, isSubOption: true, value: 'C&S Open'},
            {name: 'Off the Dribble', isSimpleCheckbox: true, value: 'OTD'},
            {name: 'No Shot Setup', isSimpleCheckbox: true, value: 'None'},
          ],
        },
        {name: 'Distance (ft)', fieldName: 'eagle_shot_distance', filterName: 'eagle_shot_distance', isDistance: true},
        {
          name: 'Shot Zone',
          fieldName: 'shot_zone_basic',
          filterName: 'shot_zone_basic',
          subOptions: [
            {name: 'Deep Paint', isSimpleCheckbox: true, value: 'DP'},
            {name: 'Corner 3s', isSimpleCheckbox: true, value: 'C3'},
            {name: 'Non-Corner 3s', isSimpleCheckbox: true, value: 'NC3'},
            {name: 'Non-Paint 2s', isSimpleCheckbox: true, value: 'NP2'},
            {name: 'Block', isSimpleCheckbox: true, value: 'BLK'},
            {name: 'High Post', isSimpleCheckbox: true, value: 'HP'},
          ],
        },
        {
          name: 'Distribution',
          fieldName: 'shot_distribution',
          filterName: 'shot_distribution',
          subOptions: [
            {name: 'Halfcourt', isSimpleCheckbox: true, value: 'HALFCOURT'},
            {name: 'Transition', isSimpleCheckbox: true, value: 'TRANSITION'},
            {name: 'Putback', isSimpleCheckbox: true, value: 'PUTBACK'},
          ],
        },
        {
          name: 'Shot Type',
          fieldName: 'synergy_shot_type',
          filterName: 'synergy_shot_type',
          isNBAExcluded: true,
          subOptions: [
            {name: 'Driving Layup', isSimpleCheckbox: true, value: 'Driving Layup'},
            {name: 'Drop Step', isSimpleCheckbox: true, value: 'Drop Step'},
            {name: 'Dunk', isSimpleCheckbox: true, value: 'Dunk'},
            {name: 'Floater/Runner', isSimpleCheckbox: true, value: 'Floater/Runner'},
            {name: 'Hook Shot', isSimpleCheckbox: true, value: 'Hook Shot'},
            {name: 'Jumper', isSimpleCheckbox: true, value: 'Jumper'},
            {name: 'Layup', isSimpleCheckbox: true, value: 'Layup'},
            {name: 'Tip Shot', isSimpleCheckbox: true, value: 'Tip Shot'},
            {name: 'Up And Under', isSimpleCheckbox: true, value: 'Up And Under'},
          ],
        },
        {name: 'qEFG', fieldName: 'shot_qefg', isQEFG: true},
        {name: 'xEFG', fieldName: 'shot_xefg', isXEFG: true},
      ]},
    ],
  },
];

export const poseShotActionItems = [
  {
    name: 'Shots',
    actionItems: [
      {
        header: 'Top',
        options: [
          {
            name: 'Shots Attributes',
            filterName: 'shotAttributes',
            subOptions: [
              {name: 'Assisted', columnName: 'assist', isAdvancedCheckbox: true},
              {name: 'Blocked', columnName: 'block', isAdvancedCheckbox: true},
              {name: 'Catch and Shoot', columnName: 'catch_and_shoot', isNCAAExcluded: true, isAdvancedCheckbox: true, isBoolean: true},
              {name: 'Fouled', columnName: 'shooting_foul', isAdvancedCheckbox: true},
              {name: 'Made', columnName: 'fg_made', isAdvancedCheckbox: true},
              {name: 'Three Pointer', columnName: 'fg_3_attempted', isAdvancedCheckbox: true, isDisabled: true},
            ],
          },
        ],
      },
      {
        header: 'Players', options: [
          {name: 'Assister', isAutocomplete: true, isSecondaryEntity: true, filterName: VideoSubject.JUMP_SHOTS_ADVANCED + videoSubjectToSecondaryPlayerLabel(VideoSubject.JUMP_SHOTS_ADVANCED), columnName: VideoPlayerFields.ASSISTER},
          {name: 'Shooter', isAutocomplete: true, isPrimaryEntity: true, filterName: VideoSubject.JUMP_SHOTS_ADVANCED + videoSubjectToPrimaryPlayerLabel(VideoSubject.JUMP_SHOTS_ADVANCED), columnName: VideoPlayerFields.SHOOTER},
        ],
      },
      {header: 'Shots Context', options: [
        {
          name: 'Contest Level',
          fieldName: 'shot_contestedness',
          filterName: 'shot_contestedness',
          isNCAAExcluded: true,
          subOptions: [
            {name: 'Low', isSimpleCheckbox: true, value: 'Low'},
            {name: 'Moderate', isSimpleCheckbox: true, value: 'Moderate'},
            {name: 'High', isSimpleCheckbox: true, value: 'High'},
          ],
        },
        {
          name: 'Setup/Contest',
          fieldName: 'shot_setup_advanced',
          filterName: 'shot_setup_advanced',
          isNBAExcluded: true,
          subOptions: [
            {name: 'Catch and Shoot', isSimpleCheckbox: true, hasChildrenOptions: true, value: ['C&S Guarded', 'C&S Open', 'C&S Other']},
            {name: 'Guarded', isSimpleCheckbox: true, isSubOption: true, value: 'C&S Guarded'},
            {name: 'Open', isSimpleCheckbox: true, isSubOption: true, value: 'C&S Open'},
            {name: 'Off the Dribble', isSimpleCheckbox: true, value: 'OTD'},
            {name: 'No Shot Setup', isSimpleCheckbox: true, value: 'None'},
          ],
        },
        {name: 'Distance (ft)', fieldName: 'eagle_shot_distance', filterName: 'eagle_shot_distance', isDistance: true},
        {
          name: 'Shot Zone',
          fieldName: 'shot_zone_basic',
          filterName: 'shot_zone_basic',
          subOptions: [
            {name: 'Deep Paint', isSimpleCheckbox: true, value: 'DP'},
            {name: 'Corner 3s', isSimpleCheckbox: true, value: 'C3'},
            {name: 'Non-Corner 3s', isSimpleCheckbox: true, value: 'NC3'},
            {name: 'Non-Paint 2s', isSimpleCheckbox: true, value: 'NP2'},
            {name: 'Block', isSimpleCheckbox: true, value: 'BLK'},
            {name: 'High Post', isSimpleCheckbox: true, value: 'HP'},
          ],
        },
        {
          name: 'Distribution',
          fieldName: 'shot_distribution',
          filterName: 'shot_distribution',
          subOptions: [
            {name: 'Halfcourt', isSimpleCheckbox: true, value: 'HALFCOURT'},
            {name: 'Transition', isSimpleCheckbox: true, value: 'TRANSITION'},
            {name: 'Putback', isSimpleCheckbox: true, value: 'PUTBACK'},
          ],
        },
        {
          name: 'Shot Type',
          fieldName: 'synergy_shot_type',
          filterName: 'synergy_shot_type',
          isNBAExcluded: true,
          subOptions: [
            {name: 'Driving Layup', isSimpleCheckbox: true, value: 'Driving Layup'},
            {name: 'Drop Step', isSimpleCheckbox: true, value: 'Drop Step'},
            {name: 'Dunk', isSimpleCheckbox: true, value: 'Dunk'},
            {name: 'Floater/Runner', isSimpleCheckbox: true, value: 'Floater/Runner'},
            {name: 'Hook Shot', isSimpleCheckbox: true, value: 'Hook Shot'},
            {name: 'Jumper', isSimpleCheckbox: true, value: 'Jumper'},
            {name: 'Layup', isSimpleCheckbox: true, value: 'Layup'},
            {name: 'Tip Shot', isSimpleCheckbox: true, value: 'Tip Shot'},
            {name: 'Up And Under', isSimpleCheckbox: true, value: 'Up And Under'},
          ],
        },
        {name: 'qEFG', fieldName: 'shot_qefg', isQEFG: true},
        {name: 'xEFG', fieldName: 'shot_xefg', isXEFG: true},
      ]},
    ],
  },
];

export const defenseItems = [
  {
    name: 'Defense',
    actionItems: [
      {
        header: 'Player',
        options: [
          {
            name: 'Defender',
            isAutocomplete: true,
            isDefensiveLineup: true,
            singular: true,
            columnName: 'defensive_player_ids',
            filterName: 'defensiveLineupEntities',
          },
        ],
      },
      {
        header: 'By Position',
        options: [
          {
            name: 'Matchup',
            filterName: 'defenseAttributes',
            isNCAAExcluded: true,
            subOptions: [
              { name: 'Point Guard', columnName: 'nba_player_ids_defending_1', group: VideoSubject.DEFENSE, isAggregatedAdvancedCheckbox: true },
              { name: 'Shooting Guard', columnName: 'nba_player_ids_defending_2', group: VideoSubject.DEFENSE, isAggregatedAdvancedCheckbox: true },
              { name: 'Small Forward', columnName: 'nba_player_ids_defending_3', group: VideoSubject.DEFENSE, isAggregatedAdvancedCheckbox: true },
              { name: 'Power Forward', columnName: 'nba_player_ids_defending_4', group: VideoSubject.DEFENSE, isAggregatedAdvancedCheckbox: true },
              { name: 'Center', columnName: 'nba_player_ids_defending_5', group: VideoSubject.DEFENSE, isAggregatedAdvancedCheckbox: true },
            ],
          },
          {
            name: 'Expected Matchup',
            filterName: 'defenseAttributesExpected',
            isNCAAExcluded: true,
            subOptions: [
              { name: 'Point Guard', columnName: 'nba_player_ids_expected_defending_1', group: VideoSubject.DEFENSE, isAggregatedAdvancedCheckbox: true },
              { name: 'Shooting Guard', columnName: 'nba_player_ids_expected_defending_2', group: VideoSubject.DEFENSE, isAggregatedAdvancedCheckbox: true },
              { name: 'Small Forward', columnName: 'nba_player_ids_expected_defending_3', group: VideoSubject.DEFENSE, isAggregatedAdvancedCheckbox: true },
              { name: 'Power Forward', columnName: 'nba_player_ids_expected_defending_4', group: VideoSubject.DEFENSE, isAggregatedAdvancedCheckbox: true },
              { name: 'Center', columnName: 'nba_player_ids_expected_defending_5', group: VideoSubject.DEFENSE, isAggregatedAdvancedCheckbox: true },
            ],
          },
        ],
      },
      {
        header: 'By LOC',
        options: [
          {
            name: 'Matchup LOC',
            filterName: 'defenseMatchupLOCs',
            isNCAAExcluded: true,
            subOptions: [
              { name: 'F', columnName: 'nba_player_ids_defending_f', group: VideoSubject.DEFENSE_LOC, isAggregatedAdvancedCheckbox: true },
              { name: 'C', columnName: 'nba_player_ids_defending_c', group: VideoSubject.DEFENSE_LOC, isAggregatedAdvancedCheckbox: true },
              { name: 'TS', columnName: 'nba_player_ids_defending_ts', group: VideoSubject.DEFENSE_LOC, isAggregatedAdvancedCheckbox: true },
              { name: 'S', columnName: 'nba_player_ids_defending_s', group: VideoSubject.DEFENSE_LOC, isAggregatedAdvancedCheckbox: true },
              { name: 'KR', columnName: 'nba_player_ids_defending_kr', group: VideoSubject.DEFENSE_LOC, isAggregatedAdvancedCheckbox: true },
              { name: 'RE', columnName: 'nba_player_ids_defending_re', group: VideoSubject.DEFENSE_LOC, isAggregatedAdvancedCheckbox: true },
              { name: 'RO', columnName: 'nba_player_ids_defending_ro', group: VideoSubject.DEFENSE_LOC, isAggregatedAdvancedCheckbox: true },
              { name: 'ML', columnName: 'nba_player_ids_defending_ml', group: VideoSubject.DEFENSE_LOC, isAggregatedAdvancedCheckbox: true },
              { name: 'Crossmatched', columnName: 'nba_player_ids_crossmatched', isAggregatedAdvancedCheckbox: true },
            ],
          },
          {
            name: 'Matchup LOC Expected',
            filterName: 'defenseMatchupLOCsExpected',
            isNCAAExcluded: true,
            subOptions: [
              { name: 'F', columnName: 'nba_player_ids_expected_defending_f', group: VideoSubject.DEFENSE_LOC, isAggregatedAdvancedCheckbox: true },
              { name: 'C', columnName: 'nba_player_ids_expected_defending_c', group: VideoSubject.DEFENSE_LOC, isAggregatedAdvancedCheckbox: true },
              { name: 'TS', columnName: 'nba_player_ids_expected_defending_ts', group: VideoSubject.DEFENSE_LOC, isAggregatedAdvancedCheckbox: true },
              { name: 'S', columnName: 'nba_player_ids_expected_defending_s', group: VideoSubject.DEFENSE_LOC, isAggregatedAdvancedCheckbox: true },
              { name: 'KR', columnName: 'nba_player_ids_expected_defending_kr', group: VideoSubject.DEFENSE_LOC, isAggregatedAdvancedCheckbox: true },
              { name: 'RE', columnName: 'nba_player_ids_expected_defending_re', group: VideoSubject.DEFENSE_LOC, isAggregatedAdvancedCheckbox: true },
              { name: 'RO', columnName: 'nba_player_ids_expected_defending_ro', group: VideoSubject.DEFENSE_LOC, isAggregatedAdvancedCheckbox: true },
              { name: 'ML', columnName: 'nba_player_ids_expected_defending_ml', group: VideoSubject.DEFENSE_LOC, isAggregatedAdvancedCheckbox: true },
            ],
          },
        ],
      },
      {
        header: 'Ball Screens',
        isNCAAExcluded: true,
        options: [
          {name: 'Ball Screen Ballhandler Defender', displayOverride: 'BH Defender', filterName: 'nbaPlayerIDsActionBallScreenBHDefender', isNCAAExcluded: true, subOptions: [{ name: 'BS BH Defender', displayOverride: 'BH Defender', columnName: 'action_ball_screen_bhdefender_nbapersonids', group: VideoSubject.DEFENSE_ACTION, isAggregatedAdvancedCheckbox: true }]},
          {name: 'Ball Screen Screener Defender', displayOverride: 'Screener Defender', isNCAAExcluded: true, filterName: 'nbaPlayerIDsActionBallScreenSCDefender', subOptions: [{ name: 'Screener Defender', columnName: 'action_ball_screen_scdefender_nbapersonids', group: VideoSubject.DEFENSE_ACTION, isAggregatedAdvancedCheckbox: true }]},
          {name: 'Switched', isNCAAExcluded: true, filterName: 'nbaPlayerIDsSwitched', subOptions: [{ name: 'Switched', columnName: 'nba_player_ids_switched', isAggregatedAdvancedCheckbox: true }]},
        ],
      },
      {
        header: 'Off-Ball Screens',
        isNCAAExcluded: true,
        options: [
          {name: 'Cutter Defender', isNCAAExcluded: true, filterName: 'nbaPlayerIDsActionOffBallScreenCTDefender', subOptions: [{ name: 'Cutter Defender', columnName: 'action_off_ball_screen_ctdefender_nbapersonids', group: VideoSubject.DEFENSE_ACTION, isAggregatedAdvancedCheckbox: true }]},
          {name: 'Off-Ball Screener Defender', displayOverride: 'Screener Defender', isNCAAExcluded: true, filterName: 'nbaPlayerIDsActionOffBallScreenSCDefender', subOptions: [{ name: 'Screener Defender', columnName: 'action_off_ball_screen_scdefender_nbapersonids', group: VideoSubject.DEFENSE_ACTION, isAggregatedAdvancedCheckbox: true }]},
        ],
      },
      {
        header: 'Isolations',
        isNCAAExcluded: true,
        options: [
          {name: 'Iso Ballhandler Defender', displayOverride: 'BH Defender', filterName: 'nbaPlayerIDsActionIsolationBHDefender', isNCAAExcluded: true, subOptions: [{ name: 'Iso BH Defender', displayOverride: 'BH Defender', columnName: 'action_isolation_bhdefender_nbapersonids', group: VideoSubject.DEFENSE_ACTION, isAggregatedAdvancedCheckbox: true }]},
        ],
      },
      {
        header: 'Drives',
        isNCAAExcluded: true,
        options: [
          {name: 'Drive Ballhandler Defender', displayOverride: 'BH Defender', filterName: 'nbaPlayerIDsActionDriveBHDefender', isNCAAExcluded: true, subOptions: [{ name: 'Drive BH Defender', displayOverride: 'BH Defender', columnName: 'action_drive_bhdefender_nbapersonids', group: VideoSubject.DEFENSE_ACTION, isAggregatedAdvancedCheckbox: true }]},
        ],
      },
      {
        header: 'Post Ups',
        isNCAAExcluded: true,
        options: [
          {name: 'Post Up Ballhandler Defender', displayOverride: 'BH Defender', filterName: 'nbaPlayerIDsActionPostBHDefender', isNCAAExcluded: true, subOptions: [{ name: 'Post Up BH Defender', displayOverride: 'BH Defender', columnName: 'action_post_bhdefender_nbapersonids', group: VideoSubject.DEFENSE_ACTION, isAggregatedAdvancedCheckbox: true }]},
          {name: 'Doubler', isNCAAExcluded: true, filterName: 'nbaPlayerIDsActionPostDoubler', subOptions: [{ name: 'Doubler', columnName: 'action_post_doubler_nbapersonids', group: VideoSubject.DEFENSE_ACTION, isAggregatedAdvancedCheckbox: true }]},
        ],
      },
      {
        header: 'Other',
        options: [
          {
            name: 'Crossmatched',
            isNCAAExcluded: true,
            subOptions: [
              { name: 'Crossmatched', filterName: 'nbaPlayerIDsCrossmatched', columnName: 'nba_player_ids_crossmatched', isAggregatedAdvancedCheckbox: true },
            ],
          },
          {
            name: 'Contested Shooter',
            isNCAAExcluded: true,
            subOptions: [
              { name: 'Contested Shooter', filterName: 'nbaPlayerIDsContestedShooter', columnName: 'nba_player_ids_contested_shooter', isAggregatedAdvancedCheckbox: true },
            ],
          },
          {
            name: 'Last Defending Shooter',
            isNCAAExcluded: true,
            subOptions: [
              { name: 'Last Defending Shooter', filterName: 'nbaPlayerIDLastDefendingShooter', columnName: 'nba_player_id_last_defending_shooter', isAggregatedAdvancedCheckbox: true },
            ],
          }
        ],
      },
      {
        header: 'Teams',
        options: [
          {
            name: 'Offensive Team',
            isAutocomplete: true,
            isOffensiveTeam: true,
            columnName: 'offensive_team_id',
            filterName: 'offensiveTeamEntities',
          },
        ],
      },
    ],
  },
];

export const offenseItems = [
  {
    name: 'Offense',
    actionItems: [
      {
        header: 'Player',
        options: [
          {
            name: 'Offensive Player',
            isAutocomplete: true,
            isOffensiveLineup: true,
            singular: true,
            columnName: 'offensive_player_ids',
            filterName: 'offensiveLineupEntities',
          },
        ],
      },
      {
        header: 'Ball Screens',
        isNCAAExcluded: true,
        options: [
          {name: 'Ball Screen Ballhandler', displayOverride: 'Ballhandler', filterName: 'nbaPlayerIDsActionBallScreenBH', isNCAAExcluded: true, subOptions: [{ name: 'BS Ballhandler', displayOverride: 'Ballhandler', columnName: 'action_ball_screen_ballhandler_nbapersonids', group: VideoSubject.OFFENSE, isAggregatedAdvancedCheckbox: true }]},
        ],
      },
      {
        header: 'Off-Ball Screens',
        isNCAAExcluded: true,
        options: [
          {name: 'Cutter', isNCAAExcluded: true, filterName: 'nbaPlayerIDsActionOffBallScreenCutter', subOptions: [{ name: 'Cutter', columnName: 'action_off_ball_screen_cutter_nbapersonids', group: VideoSubject.OFFENSE, isAggregatedAdvancedCheckbox: true }]},
          {name: 'Off-Ball Screener', displayOverride: 'Screener', isNCAAExcluded: true, filterName: 'nbaPlayerIDsActionOffBallScreenScreener', subOptions: [{ name: 'Screener', columnName: 'action_off_ball_screen_screener_nbapersonids', group: VideoSubject.OFFENSE, isAggregatedAdvancedCheckbox: true }]},
          {name: 'Toucher', isNCAAExcluded: true, filterName: 'nbaPlayerIDsActionOffBallScreenToucher', subOptions: [{ name: 'Toucher', columnName: 'action_off_ball_screen_toucher_nbapersonids', group: VideoSubject.OFFENSE, isAggregatedAdvancedCheckbox: true }]},
        ],
      },
      {
        header: 'Isolations',
        isNCAAExcluded: true,
        options: [
          {name: 'Iso Ballhandler', displayOverride: 'Ballhandler', filterName: 'nbaPlayerIDsActionIsolationBH', isNCAAExcluded: true, subOptions: [{ name: 'Iso Ballhandler', displayOverride: 'Ballhandler', columnName: 'action_isolation_ballhandler_nbapersonids', group: VideoSubject.OFFENSE, isAggregatedAdvancedCheckbox: true }]},
        ],
      },
      {
        header: 'Drives',
        isNCAAExcluded: true,
        options: [
          {name: 'Drive Ballhandler', displayOverride: 'Ballhandler', filterName: 'nbaPlayerIDsActionDriveBH', isNCAAExcluded: true, subOptions: [{ name: 'Drive Ballhandler', displayOverride: 'Ballhandler', columnName: 'action_drive_ballhandler_nbapersonids', group: VideoSubject.OFFENSE, isAggregatedAdvancedCheckbox: true }]},
        ],
      },
      {
        header: 'Post Ups',
        isNCAAExcluded: true,
        options: [
          {name: 'Post Up Ballhandler', displayOverride: 'Ballhandler', filterName: 'nbaPlayerIDsActionPostBH', isNCAAExcluded: true, subOptions: [{ name: 'Post Up Ballhandler', displayOverride: 'Ballhandler', columnName: 'action_post_ballhandler_nbapersonids', group: VideoSubject.OFFENSE, isAggregatedAdvancedCheckbox: true }]},
        ],
      },
      {
        header: 'Teams',
        options: [
          {
            name: 'Defensive Team',
            isAutocomplete: true,
            isDefensiveTeam: true,
            columnName: 'defensive_team_id',
            filterName: 'defensiveTeamEntities',
          },
        ],
      },
    ],
  },
];

export const assistActionItems = [
  {
    name: 'Assists',
    actionItems: [
      {
        header: 'Players', options: [
          {name: 'Assister', isAutocomplete: true, isPrimaryEntity: true, filterName: VideoSubject.ASSISTS + videoSubjectToPrimaryPlayerLabel(VideoSubject.ASSISTS), columnName: VideoPlayerFields.ASSISTER},
          {name: 'Shooter', isAutocomplete: true, isSecondaryEntity: true, filterName: VideoSubject.ASSISTS + videoSubjectToSecondaryPlayerLabel(VideoSubject.ASSISTS), columnName: VideoPlayerFields.SHOOTER},
        ],
      },
    ],
  },
];

export const touchesActionItems = [
  {
    name: 'Touches',
    actionItems: [
      {
        header: 'Touches', options: [
          {name: 'Toucher', isAutocomplete: true, isPrimaryEntity: true, filterName: 'abstractPlayer', tableName: VideoTables.TOUCHER, columnName: VideoPlayerFields.TOUCHER},
          {
            name: 'Start Type',
            fieldName: 'starttype',
            filterName: 'starttype',
            tableName: VideoTables.TOUCHES,
            subOptions: touchStartTypeOptions,
          },
          {
            name: 'Outcomes',
            fieldName: 'outcomes',
            filterName: 'outcomes',
            tableName: VideoTables.TOUCHES,
            subOptions: touchOutcomeOptions,
          },
          {
            name: 'Regions',
            fieldName: 'regionsin',
            filterName: 'regionsin',
            tableName: VideoTables.TOUCHES,
            subOptions: touchRegionOptions,
          },
          {
            name: 'Potential Catch & Shoot',
            fieldName: 'potentialcatchandshoot',
            filterName: 'potentialcatchandshoot',
            tableName: VideoTables.TOUCHES,
            subOptions: touchPotentialCatchAndShootOptions,
          },
          {
            name: 'Number Dribbles',
            fieldName: 'numdribbles',
            filterName: 'numdribbles',
            tableName: VideoTables.TOUCHES,
            isDribbles: true,
          },
          {
            name: 'Duration',
            fieldName: 'touchtime',
            filterName: 'touchtime',
            tableName: VideoTables.TOUCHES,
            isTouchTime: true,
          },
        ],
      },
    ],
  },
];

export const chartingActionItems = [
  {
    name: 'Charting',
    actionItems: [
      {
        header: 'Charting', options: [
          // TODO: Update?
          {name: 'Player', isAutocomplete: true, isPrimaryEntity: true, filterName: VideoSubject.CHARTING + videoSubjectToPrimaryPlayerLabel(VideoSubject.CHARTING), tableName: VideoTables.CHARTING, columnName: VideoPlayerFields.CHARTING_PLAYER},
          {
            name: 'Charting Category',
            fieldName: 'charting_category_name',
            filterName: 'charting_category_name',
            tableName: VideoTables.CHARTING,
            subOptions: chartingCategoryOptions,
          },
          {
            name: 'Charting Result',
            fieldName: 'charting_result',
            filterName: 'charting_result',
            tableName: VideoTables.CHARTING,
            subOptions: chartingResultOptions,
          },
        ],
      },
    ],
  },
];

export const reboundActionItems = [
  {
    name: 'Rebounds',
    actionItems: [
      {
        header: 'Players', options: [
          {name: 'Rebounder', isAutocomplete: true, isPrimaryEntity: true, filterName: VideoSubject.REBOUNDS + videoSubjectToPrimaryPlayerLabel(VideoSubject.REBOUNDS), columnName: VideoPlayerFields.REBOUNDER},
        ],
      },
    ],
  },
];

export const stealActionItems = [
  {
    name: 'Steals',
    actionItems: [
      {
        header: 'Players', options: [
          {name: 'Stealer', isAutocomplete: true, isPrimaryEntity: true, filterName: VideoSubject.STEALS + videoSubjectToPrimaryPlayerLabel(VideoSubject.STEALS), columnName: VideoPlayerFields.STEALER},
          {name: 'Turnover Player', isAutocomplete: true, isSecondaryEntity: true, filterName: VideoSubject.STEALS + videoSubjectToSecondaryPlayerLabel(VideoSubject.STEALS), columnName: VideoPlayerFields.TURNOVERER},
        ],
      },
    ],
  },
];

export const blockActionItems = [
  {
    name: 'Blocks',
    actionItems: [
      {
        header: 'Players', options: [
          {name: 'Blocker', isAutocomplete: true, isPrimaryEntity: true, filterName: VideoSubject.BLOCKS + videoSubjectToPrimaryPlayerLabel(VideoSubject.BLOCKS), columnName: VideoPlayerFields.BLOCKER},
          {name: 'Shooter', isAutocomplete: true, isSecondaryEntity: true, filterName: VideoSubject.BLOCKS + videoSubjectToSecondaryPlayerLabel(VideoSubject.BLOCKS), columnName: VideoPlayerFields.SHOOTER},
        ],
      },
    ],
  },
];

export const turnoverActionItems = [
  {
    name: 'Turnovers',
    actionItems: [
      {
        header: 'Players', options: [
          {name: 'Turnover Player', isAutocomplete: true, isPrimaryEntity: true, filterName: VideoSubject.TURNOVERS + videoSubjectToPrimaryPlayerLabel(VideoSubject.TURNOVERS), columnName: VideoPlayerFields.TURNOVERER},
          {name: 'Stealer', isAutocomplete: true, isSecondaryEntity: true, filterName: VideoSubject.TURNOVERS + videoSubjectToSecondaryPlayerLabel(VideoSubject.TURNOVERS), columnName: VideoPlayerFields.STEALER},
        ],
      },
    ],
  },
];

export const foulActionItems = [
  {
    name: 'Fouls',
    actionItems: [
      {
        header: 'Players', options: [
          {name: 'Fouler', isAutocomplete: true, isPrimaryEntity: true, filterName: VideoSubject.FOULS + videoSubjectToPrimaryPlayerLabel(VideoSubject.FOULS), columnName: VideoPlayerFields.FOULER},
          {name: 'Fouled Player', isNCAAExcluded: true, isAutocomplete: true, isSecondaryEntity: true, filterName: VideoSubject.FOULS + videoSubjectToSecondaryPlayerLabel(VideoSubject.FOULS), columnName: VideoPlayerFields.FOULEE},
        ],
      },
    ],
  },
];

export const freeThrowActionItems = [
  {
    name: 'Free Throws',
    actionItems: [
      {
        header: 'Players', options: [
          {name: 'Shooter', isAutocomplete: true, isPrimaryEntity: true, filterName: VideoSubject.FREE_THROWS + videoSubjectToPrimaryPlayerLabel(VideoSubject.FREE_THROWS), tableName: VideoTables.FREE_THROWS, columnName: 'nbapersonid'},
        ],
      },
    ],
  },
];

export const abstractActionItem = {
  name: 'Player',
  isAction: true,
  actionItems: [
    {
      header: 'Player', options: [
        {name: 'Player', isAutocomplete: true, isAbstractEntity: true, filterName: 'abstractPlayer', tableName: 'abstract', columnName: 'subject_player_id'},
      ],
    },
  ],
};

export const abstractActionItems = [
  abstractActionItem,
];

export const abstractEventItem = {
  name: 'Player',
  actionItems: [
    {
      header: 'Player', options: [
        {name: 'Player', isAutocomplete: true, isPrimaryEntity: true, filterName: 'Player', tableName: 'abstract', columnName: 'subject_player_id'},
      ],
    },
  ],
};

export const abstractEventItems = [
  abstractEventItem,
];

export const cutsActionItems = [
  {
    action: VideoSubject.CUTS,
    name: 'Cuts',
    actionItems: [
      {
        header: 'Cuts',
        options: [
          {
            name: 'Cuts Attributes',
            filterName: 'cutsAttributes',
            subOptions: [
              {name: 'Basket', value: 'Basket', columnName: 'descriptors', group: VideoSubject.CUTS, isAggregatedAdvancedCheckbox: true},
              {name: 'Screen', value: 'Screen', columnName: 'descriptors', group: VideoSubject.CUTS, isAggregatedAdvancedCheckbox: true},
              {name: 'Flash', value: 'Flash', columnName: 'descriptors', group: VideoSubject.CUTS, isAggregatedAdvancedCheckbox: true},
            ],
          },
        ],
      },
    ],
  },
];

export const drivesActionItems = [
  {
    action: VideoSubject.DRIVES,
    name: 'Drives',
    actionItems: [
      {
        header: 'Drives',
        options: [
          {
            name: 'Drives Attributes',
            filterName: 'drivesAttributes',
            subOptions: [
              {name: 'Defense Commits', value: 'DefenseCommits', columnName: 'descriptors', group: VideoSubject.DRIVES, isAggregatedAdvancedCheckbox: true},
              {name: 'Drives Right', value: 'DrivesRight', columnName: 'descriptors', group: VideoSubject.DRIVES, isAggregatedAdvancedCheckbox: true},
              {name: 'Drives Straight', value: 'DrivesStraight', columnName: 'descriptors', group: VideoSubject.DRIVES, isAggregatedAdvancedCheckbox: true},
              {name: 'To Basket', value: 'ToBasket', columnName: 'descriptors', group: VideoSubject.DRIVES, isAggregatedAdvancedCheckbox: true},
              {name: 'Drive Middle', value: 'DriveMiddle', columnName: 'descriptors', group: VideoSubject.DRIVES, isAggregatedAdvancedCheckbox: true},
              {name: 'Drives Left', value: 'DrivesLeft', columnName: 'descriptors', group: VideoSubject.DRIVES, isAggregatedAdvancedCheckbox: true},
              {name: 'Drive Baseline', value: 'DriveBaseline', columnName: 'descriptors', group: VideoSubject.DRIVES, isAggregatedAdvancedCheckbox: true},
            ],
          },
        ],
      },
    ],
  },
];

export const handoffsActionItems = [
  {
    action: VideoSubject.HANDOFFS,
    name: 'Handoffs',
    actionItems: [
      {
        header: 'Handoffs',
        options: [
          {
            name: 'Handoffs Attributes',
            filterName: 'handoffsAttributes',
            subOptions: [
              {name: 'Left', value: 'Left', columnName: 'descriptors', group: VideoSubject.HANDOFFS, isAggregatedAdvancedCheckbox: true},
              {name: 'From Dribble', value: 'FromDribble', columnName: 'descriptors', group: VideoSubject.HANDOFFS, isAggregatedAdvancedCheckbox: true},
              {name: 'To Basket', value: 'ToBasket', columnName: 'descriptors', group: VideoSubject.HANDOFFS, isAggregatedAdvancedCheckbox: true},
              {name: 'From Stationary', value: 'FromStationary', columnName: 'descriptors', group: VideoSubject.HANDOFFS, isAggregatedAdvancedCheckbox: true},
              {name: 'Top', value: 'Top', columnName: 'descriptors', group: VideoSubject.HANDOFFS, isAggregatedAdvancedCheckbox: true},
              {name: 'Right', value: 'Right', columnName: 'descriptors', group: VideoSubject.HANDOFFS, isAggregatedAdvancedCheckbox: true},
            ],
          },
        ],
      },
    ],
  },
];

export const isloationActionItems = [
  {
    action: VideoSubject.ISOLATION,
    name: 'Isolation',
    actionItems: [
      {
        header: 'Isolation',
        options: [
          {
            name: 'Isolation Attributes',
            filterName: 'isolationAttributes',
            subOptions: [
              {name: 'Left', value: 'Left', columnName: 'descriptors', group: VideoSubject.ISOLATION, isAggregatedAdvancedCheckbox: true},
              {name: 'From Dribble', value: 'FromDribble', columnName: 'descriptors', group: VideoSubject.ISOLATION, isAggregatedAdvancedCheckbox: true},
              {name: 'To Basket', value: 'ToBasket', columnName: 'descriptors', group: VideoSubject.ISOLATION, isAggregatedAdvancedCheckbox: true},
              {name: 'From Stationary', value: 'FromStationary', columnName: 'descriptors', group: VideoSubject.ISOLATION, isAggregatedAdvancedCheckbox: true},
              {name: 'Top', value: 'Top', columnName: 'descriptors', group: VideoSubject.ISOLATION, isAggregatedAdvancedCheckbox: true},
              {name: 'Right', value: 'Right', columnName: 'descriptors', group: VideoSubject.ISOLATION, isAggregatedAdvancedCheckbox: true},
            ],
          },
        ],
      },
    ],
  },
];

export const picksActionItems = [
  {
    action: VideoSubject.PICKS,
    name: 'Picks',
    actionItems: [
      {
        header: 'Picks',
        options: [
          {
            name: 'Off-Ball Screen',
            filterName: 'OffBallScreenAttributes',
            subOptions: [
              {name: 'Fade', value: 'Fade', columnName: 'descriptors', group: VideoSubject.PICKS, isAggregatedAdvancedCheckbox: true},
              {name: 'Flare', value: 'Flare', columnName: 'descriptors', group: VideoSubject.PICKS, isAggregatedAdvancedCheckbox: true},
              {name: 'Curl', value: 'Curl', columnName: 'descriptors', group: VideoSubject.PICKS, isAggregatedAdvancedCheckbox: true},
              {name: 'To Basket', value: 'ToBasket', columnName: 'descriptors', group: VideoSubject.PICKS, isAggregatedAdvancedCheckbox: true},
              {name: 'To Shooters Right', value: 'ToShootersRight', columnName: 'descriptors', group: VideoSubject.PICKS, isAggregatedAdvancedCheckbox: true},
              {name: 'Straight', value: 'Straight', columnName: 'descriptors', group: VideoSubject.PICKS, isAggregatedAdvancedCheckbox: true},
              {name: 'To Shooters Left', value: 'ToShootersLeft', columnName: 'descriptors', group: VideoSubject.PICKS, isAggregatedAdvancedCheckbox: true},
            ],
          },
          {
            name: 'On-Ball Screen',
            filterName: 'OnBallScreenAttributes',
            subOptions: [
              {name: 'Dribble Off Pick', value: 'DribbleOffPick', columnName: 'descriptors', group: VideoSubject.PICKS, isAggregatedAdvancedCheckbox: true},
              {name: 'Slips the Pick', value: 'SlipsthePick', columnName: 'descriptors', group: VideoSubject.PICKS, isAggregatedAdvancedCheckbox: true},
              {name: 'Defense Commits', value: 'DefenseCommits', columnName: 'descriptors', group: VideoSubject.PICKS, isAggregatedAdvancedCheckbox: true},
              {name: 'High P&R', value: 'HighPandR', columnName: 'descriptors', group: VideoSubject.PICKS, isAggregatedAdvancedCheckbox: true},
              {name: 'Left P&R', value: 'LeftPandR', columnName: 'descriptors', group: VideoSubject.PICKS, isAggregatedAdvancedCheckbox: true},
              {name: 'Go Away from Pick', value: 'GoAwayfromPick', columnName: 'descriptors', group: VideoSubject.PICKS, isAggregatedAdvancedCheckbox: true},
              {name: 'Pick&Pops', value: 'PickandPops', columnName: 'descriptors', group: VideoSubject.PICKS, isAggregatedAdvancedCheckbox: true},
              {name: 'Right P&R', value: 'RightPandR', columnName: 'descriptors', group: VideoSubject.PICKS, isAggregatedAdvancedCheckbox: true},
              {name: 'Split', value: 'Split', columnName: 'descriptors', group: VideoSubject.PICKS, isAggregatedAdvancedCheckbox: true},
              {name: 'To Basket', value: 'ToBasket', columnName: 'descriptors', group: VideoSubject.PICKS, isAggregatedAdvancedCheckbox: true},
              {name: 'Rolls to Basket', value: 'RollstoBasket', columnName: 'descriptors', group: VideoSubject.PICKS, isAggregatedAdvancedCheckbox: true},
              {name: 'P&R Ball Handler', value: 'PandRBallHandler', columnName: 'descriptors', group: VideoSubject.PICKS, isAggregatedAdvancedCheckbox: true},
              {name: 'Takes Early Jump Shot', value: 'TakesEarlyJumpShot', columnName: 'descriptors', group: VideoSubject.PICKS, isAggregatedAdvancedCheckbox: true},
              {name: 'Baseline', value: 'Baseline', columnName: 'descriptors', group: VideoSubject.PICKS, isAggregatedAdvancedCheckbox: true},
              {name: 'P&R Roll Man', value: 'PandRRollMan', columnName: 'descriptors', group: VideoSubject.PICKS, isAggregatedAdvancedCheckbox: true},
              {name: 'Side', value: 'Side', columnName: 'descriptors', group: VideoSubject.PICKS, isAggregatedAdvancedCheckbox: true},
            ],
          },
        ],
      },
    ],
  },
];

export const postUpActionItems = [
  {
    action: VideoSubject.POST_UPS,
    name: 'Post Up',
    actionItems: [
      {
        header: 'Post Up',
        options: [
          {
            name: 'Post Up Attributes',
            filterName: 'postUpAttributes',
            subOptions: [
              {name: 'Defense Commits', value: 'DefenseCommits', columnName: 'descriptors', group: VideoSubject.POST_UPS, isAggregatedAdvancedCheckbox: true},
              {name: 'Flash Middle', value: 'FlashMiddle', columnName: 'descriptors', group: VideoSubject.POST_UPS, isAggregatedAdvancedCheckbox: true},
              {name: 'Right Shoulder', value: 'RightShoulder', columnName: 'descriptors', group: VideoSubject.POST_UPS, isAggregatedAdvancedCheckbox: true},
              {name: 'Post Pin', value: 'PostPin', columnName: 'descriptors', group: VideoSubject.POST_UPS, isAggregatedAdvancedCheckbox: true},
              {name: 'To Basket', value: 'ToBasket', columnName: 'descriptors', group: VideoSubject.POST_UPS, isAggregatedAdvancedCheckbox: true},
              {name: 'Face Up', value: 'Face_up', columnName: 'descriptors', group: VideoSubject.POST_UPS, isAggregatedAdvancedCheckbox: true},
              {name: 'Right Block', value: 'RightBlock', columnName: 'descriptors', group: VideoSubject.POST_UPS, isAggregatedAdvancedCheckbox: true},
              {name: 'Left Shoulder', value: 'LeftShoulder', columnName: 'descriptors', group: VideoSubject.POST_UPS, isAggregatedAdvancedCheckbox: true},
              {name: 'Left Block', value: 'LeftBlock', columnName: 'descriptors', group: VideoSubject.POST_UPS, isAggregatedAdvancedCheckbox: true},
            ],
          },
        ],
      },
    ],
  },
];

export const spotUpActionItems = [
  {
    action: VideoSubject.SPOT_UPS,
    name: 'Spot Up',
    actionItems: [
      {
        header: 'Spot Up',
        options: [
          {
            name: 'Spot Up Attributes',
            filterName: 'spotUpAttributes',
            subOptions: [
              {name: 'To Basket', value: 'ToBasket', columnName: 'descriptors', group: VideoSubject.SPOT_UPS, isAggregatedAdvancedCheckbox: true},
            ],
          },
        ],
      },
    ],
  },
];

export const transitionActionItems = [
  {
    action: VideoSubject.TRANSITION,
    name: 'Transition',
    actionItems: [
      {
        header: 'Transition',
        options: [
          {
            name: 'Transition Attributes',
            filterName: 'transitionAttributes',
            subOptions: [
              {name: 'Trailer', value: 'Trailer', columnName: 'descriptors', group: VideoSubject.TRANSITION, isAggregatedAdvancedCheckbox: true},
              {name: 'Ball Handler', value: 'Ballhandler', columnName: 'descriptors', group: VideoSubject.TRANSITION, isAggregatedAdvancedCheckbox: true},
              {name: 'Leak Outs', value: 'LeakOuts', columnName: 'descriptors', group: VideoSubject.TRANSITION, isAggregatedAdvancedCheckbox: true},
              {name: 'To Basket', value: 'ToBasket', columnName: 'descriptors', group: VideoSubject.TRANSITION, isAggregatedAdvancedCheckbox: true},
              {name: 'Right Wing', value: 'RightWing', columnName: 'descriptors', group: VideoSubject.TRANSITION, isAggregatedAdvancedCheckbox: true},
              {name: 'First Middle', value: 'FirstMiddle', columnName: 'descriptors', group: VideoSubject.TRANSITION, isAggregatedAdvancedCheckbox: true},
              {name: 'Left Wing', value: 'LeftWing', columnName: 'descriptors', group: VideoSubject.TRANSITION, isAggregatedAdvancedCheckbox: true},
            ],
          },
        ],
      },
    ],
  },
];

export const possessionsAndChancesItem = {
  name: 'Possessions & Chances',
  actionItems: [
    {
      header: 'Top', options: [
        {name: 'Possessions', isNCAAExcluded: true, isPossChance: true, label: 'Possession IDs', columnName: 'nba_possession_id', filterName: 'possessions'},
        {name: 'Eagle Chances', isNCAAExcluded: true, isPossChance: true, label: 'Chance IDs', columnName: 'eagle_chance_id', filterName: 'eagleChances'},
        {name: 'NBA Chances', isNCAAExcluded: true, isPossChance: true, label: 'Chance IDs', columnName: 'nba_chance_id', filterName: 'nbaChances'},
        {name: 'Events', isNBAExcluded: true, isPossChance: true, label: 'Synergy Event IDs', columnName: 'synergy_event_id', filterName: 'events'},
        {name: 'Transition', isNCAAExcluded: true, subOptions: [{name: 'Transition', columnName: 'eagle_transition_flag', isAdvancedCheckbox: true}], isBoolean: true}
      ],
    },
    {
      header: 'Players', options: [
        {name: 'On Court', isAutocomplete: true, isOnCourt: true, columnName: VideoPlayerFields.ON_COURT, filterName: 'onCourtEntities'},
        {name: 'Off Court', isAutocomplete: true, isOffCourt: true, columnName: VideoPlayerFields.ON_COURT, filterName: 'offCourtEntities'},
        {name: 'Offensive Lineup', isAutocomplete: true, isOffensiveLineup: true, columnName: 'offensive_player_ids', filterName: 'offensiveLineupEntities'},
        {name: 'Defensive Lineup', isAutocomplete: true, isDefensiveLineup: true, columnName: 'defensive_player_ids', filterName: 'defensiveLineupEntities'},
        {name: 'Primary Defender', isAutocomplete: true, isPrimaryDefender: true, columnName: 'primary_defensive_player_id', filterName: 'primaryDefensivePlayer', isNBAExcluded: true},
      ],
    },
    {
      header: 'Teams', options: [
        {name: 'Defensive Team', isAutocomplete: true, isDefensiveTeam: true, columnName: 'defensive_team_id', filterName: 'defensiveTeamEntities'},
        {name: 'Offensive Team', isAutocomplete: true, isOffensiveTeam: true, columnName: 'offensive_team_id', filterName: 'offensiveTeamEntities'},
        {name: 'Team', isAutocomplete: true, isTeams: true, columnName: 'on_court_team_ids', filterName: 'teamEntities'},
      ],
    },
  ],
};

export const DefOffPossessionsAndChancesItem = {
  name: 'Possessions & Chances',
  actionItems: [
    {
      header: 'Top', options: [
        {name: 'Possessions', isNCAAExcluded: true, isPossChance: true, label: 'Possession IDs', columnName: 'nba_possession_id', filterName: 'possessions'},
        {name: 'Eagle Chances', isNCAAExcluded: true, isPossChance: true, label: 'Chance IDs', columnName: 'eagle_chance_id', filterName: 'eagleChances'},
        {name: 'NBA Chances', isNCAAExcluded: true, isPossChance: true, label: 'Chance IDs', columnName: 'nba_chance_id', filterName: 'nbaChances'},
        {name: 'Events', isNBAExcluded: true, isPossChance: true, label: 'Synergy Event IDs', columnName: 'synergy_event_id', filterName: 'events'},
        {name: 'Transition', isNCAAExcluded: true, subOptions: [{name: 'Transition', columnName: 'eagle_transition_flag', isAdvancedCheckbox: true}], isBoolean: true}
      ],
    },
    {
      header: 'Players', options: [
        {name: 'On Court', isAutocomplete: true, isOnCourt: true, columnName: VideoPlayerFields.ON_COURT, filterName: 'onCourtEntities'},
        {name: 'Off Court', isAutocomplete: true, isOffCourt: true, columnName: VideoPlayerFields.ON_COURT, filterName: 'offCourtEntities'},
      ],
    },
  ],
};

export const teamsActionItem = {
  name: 'Teams',
  actionItems: [
    {
      header: 'Teams', options: [
        {name: 'Teams', isAutocomplete: true, isTeams: true, filterName: 'teams'},
      ],
    },
  ],
};

export const gamesActionItem = {
  name: 'Games',
  actionItems: [
    {
      header: 'Top',
      options: [
        {name: 'Season', isSeasons: true, filterName: 'season'},
      ],
    },
    {
      header: 'Games', options: [
        {name: 'Games', isGames: true, filterName: 'ctgGames'},
        {
          name: 'Game Types',
          fieldName: 'game_type',
          filterName: 'game_type',
          isNCAAExcluded: true,
          subOptions: [
            {name: 'Preseason', isSimpleCheckbox: true, value: 1},
            {name: 'Regular Season', isSimpleCheckbox: true, value: 2},
            {name: 'Postseason', isSimpleCheckbox: true, value: 4},
          ],
        },
        {name: 'Date', isDates: true, filterName: 'minDate'},
      ],
    },
  ],
};

export const timeActionItem = {
  name: 'Time',
  actionItems: [
    {
      header: 'Time', options: [
        {name: 'Periods', isPeriods: true, excludeOnPeriods: true, filterName: 'periods'},
        {name: 'Game Clock', isGameClock: true, excludeOnPeriods: true, filterName: 'minGameclock'},
        {name: 'Shot Clock', isShotClock: true, excludeOnPeriods: true, filterName: 'minShotclock'},
      ],
    },
  ],
};

export const allActionItems = [
  ...shotActionItems,
  ...defenseItems,
  ...offenseItems,
  ...assistActionItems,
  ...touchesActionItems,
  ...chartingActionItems,
  ...reboundActionItems,
  ...stealActionItems,
  ...blockActionItems,
  ...turnoverActionItems,
  ...foulActionItems,
  ...freeThrowActionItems,
  ...abstractActionItems,
  ...abstractEventItems,
  ...cutsActionItems,
  ...drivesActionItems,
  ...handoffsActionItems,
  ...isloationActionItems,
  ...picksActionItems,
  ...postUpActionItems,
  ...spotUpActionItems,
  ...transitionActionItems,
  possessionsAndChancesItem,
  teamsActionItem,
  gamesActionItem,
  timeActionItem,
];

export const allPoseActionItems = [
  ...shotActionItems,
  ...poseShotActionItems,
  ...defenseItems,
  ...offenseItems,
  ...assistActionItems,
  ...touchesActionItems,
  ...chartingActionItems,
  ...reboundActionItems,
  ...stealActionItems,
  ...blockActionItems,
  ...turnoverActionItems,
  ...foulActionItems,
  ...freeThrowActionItems,
  ...abstractActionItems,
  ...abstractEventItems,
  ...cutsActionItems,
  ...drivesActionItems,
  ...handoffsActionItems,
  ...isloationActionItems,
  ...picksActionItems,
  ...postUpActionItems,
  ...spotUpActionItems,
  ...transitionActionItems,
  possessionsAndChancesItem,
  teamsActionItem,
  gamesActionItem,
  timeActionItem,
];

export const synergyFilters = [
  ...cutsActionItems,
  ...drivesActionItems,
  ...handoffsActionItems,
  ...isloationActionItems,
  ...picksActionItems,
  ...postUpActionItems,
  ...spotUpActionItems,
  ...transitionActionItems,
];
